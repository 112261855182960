import React from 'react';
import { Pagination } from 'react-bootstrap';
import { logger } from '../../wrapper/Logger';
import './Paginator.css';

/** The paginator lets you control the paging of the PagedTableSearch.
 * You can set showPages to the number of pages you want to offer in the paginator.
 * Default for showPages is 5, always use odd numbers!
 * @param {number} currentPage - the current page state
 * @param {number} totalPages - the total number of pages state
 * @param {function} setCurrentPage - the function to set the current page state
 * @param {number} showPages - the number of pages to show, this has to be an odd number!
  */
const defaultShowPages = 5;

const Paginator = ({
  currentPage, totalPages, setCurrentPage, showPages = defaultShowPages,
}) => {
  const lastPage = totalPages - 1;
  const firstPage = 0;
  const items = [];

  const middleStartFrom = () => {
    return Math.floor(showPages / 2);
  };
  const middleEndAt = () => {
    return Math.ceil(showPages / 2);
  };
  const noPages = totalPages === 0;

  // check if showPages is odd and set to default if not
  if (showPages % 2 === 0) {
    logger.warn('showPages has to be an odd number, setting to default of ', defaultShowPages);
    // eslint-disable-next-line no-param-reassign
    showPages = defaultShowPages;
  }

  // add first and prev button
  items.push(
    <Pagination.First
      key="first"
      onClick={() => setCurrentPage(firstPage)}
      disabled={currentPage === firstPage || noPages}
    />,
    <Pagination.Prev key="prev" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === firstPage || noPages} />,
  );

  // add page numbers if totalPages <= showPages
  if (totalPages <= showPages) {
    for (let number = firstPage; number < totalPages; number += 1) {
      items.push(
        <Pagination.Item
          key={number}
          onClick={() => setCurrentPage(number)}
          active={number === currentPage}
        >
          {number + 1}
        </Pagination.Item>,
      );
    }
  } else if (currentPage <= middleStartFrom()) {
    // for the first pages
    for (let number = firstPage; number < showPages; number += 1) {
      items.push(
        <Pagination.Item
          key={number}
          onClick={() => setCurrentPage(number)}
          active={number === currentPage}
        >
          {number + 1}
        </Pagination.Item>,
      );
    }
  } else if (currentPage >= middleStartFrom() && currentPage <= totalPages - middleEndAt()) {
    // if currently in the middle
    for (
      let number = currentPage - middleStartFrom();
      number < currentPage + middleEndAt();
      number += 1
    ) {
      items.push(
        <Pagination.Item
          key={number}
          onClick={() => setCurrentPage(number)}
          active={number === currentPage}
        >
          {number + 1}
        </Pagination.Item>,
      );
    }
  } else if (currentPage >= totalPages - middleEndAt()) {
    // for the last pages
    for (let number = totalPages - showPages; number < totalPages; number += 1) {
      items.push(
        <Pagination.Item
          key={number}
          onClick={() => setCurrentPage(number)}
          active={number === currentPage}
        >
          {number + 1}
        </Pagination.Item>,
      );
    }
  } else if (noPages) {
    // ellipsis
    items.push(
      <Pagination.Ellipsis key="ellipsis" disabled />,
    );
  }

  // add next and last button
  items.push(
    <Pagination.Next key="next" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === lastPage || noPages} />,
    <Pagination.Last key="last" onClick={() => setCurrentPage(lastPage)} disabled={currentPage === lastPage || noPages} />,
  );

  return (
    <Pagination>
      {items}
    </Pagination>
  );
};

export default Paginator;
