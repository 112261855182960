import deleteByUrl from '../../api/DeleteByUrl';
import postData from '../../api/PostData';
import patchByUrl from '../../api/PatchByUrl';
import { logger } from '../../wrapper/Logger';
import ErrorToast from '../toasts/ErrorToast';
import ErrorToastWithButton from '../toasts/ErrorToastWithButton';

function closeModal(id) {
  // get close button from modal and click it
  // because bootstrap modal.hide() doesn't work
  const myModal = document.getElementById(id);
  myModal.click();
}

// trim all string values of an object
function trimObjectValues(obj) {
  const trimmedValues = {};
  Object.keys(obj).forEach((k) => {
    trimmedValues[k] = typeof obj[k] === 'string' ? obj[k].trim() : obj[k];
  });
  return trimmedValues;
}

// convert amount from euro to cents
const amountFromEuroToCents = (values) => {
  if (values.priceInCents !== undefined && typeof values.priceInCents === 'string') {
    return { ...values, priceInCents: parseFloat(values.priceInCents.replace(',', '.')) * 100 };
  }
  if (values.amountInCents !== undefined && typeof values.amountInCents === 'string') {
    return { ...values, amountInCents: parseFloat(values.amountInCents.replace(',', '.')) * 100 };
  }

  return values;
};

export const handleChange = (event, entity, setEntity) => {
  setEntity({ ...entity, [event.target.name]: event.target.value });
};

// for single select
export const handleSelectChange = (event, entity, setEntity) => {
  setEntity({ ...entity, [event.field]: event.value });
};

export const handleDelete = async (selfLink, setUpdate) => {
  await deleteByUrl(selfLink);
  setUpdate(true);
};

export const handleSubmit = async (
  values,
  submitProps,
  setUpdate,
  urlEntity,
  selfLink,
) => {
  let manipulatedValues = trimObjectValues(values);
  manipulatedValues = amountFromEuroToCents(manipulatedValues);

  let error;
  // if entity has _links property, create new entity
  // else update entity
  if (selfLink === undefined) {
    error = await postData(urlEntity, manipulatedValues);
  } else {
    error = await patchByUrl(selfLink, manipulatedValues);
  }

  if (error === undefined || error === null) {
    // update table
    setUpdate(true);
    closeModal('button-close-modal');
  } else {
    const errorObject = JSON.parse(error.message);

    if (errorObject.errors !== undefined && errorObject.errors.map((errorSegment) => errorSegment.message.includes('Customer Number already exists'))) {
      ErrorToast('Kunden Nr. existiert bereits');
    } else {
      ErrorToastWithButton(error);
      logger.error(error);

      // update table
      setUpdate(true);
      closeModal('button-close-modal');
    }
  }
};
