import React from 'react';
import { ToastContainer } from 'react-toastify';
import Navbar from './components/layout/Navbar';
import Sidebar from './components/layout/Sidebar';

const Layout = ({ children }) => {
  let sidebarLinks;
  return (
    <>
      <Navbar />
      <div className="d-flex">
        <div className="col-auto layout-sidebar d-none d-md-block shadow">
          <Sidebar sidebarLinks={sidebarLinks} />
        </div>
        <div className="col-auto layout-content">
          <main className="py-3">
            {children}
          </main>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
};

export default Layout;
