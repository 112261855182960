/** Bivema holds the names for all entities/relations of the bivema api.
 * Use this constant to avoid retyping entity names in multiple places
 * and to avoid typos.
 * Usage example: Bivema.ADDRESS
*/
const Bivema = {
  ADDRESS: 'address',
  ADDRESSES: 'addresses',
  AZAV_CERTIFICATION: 'azavCertification',
  AZAV_CERTIFICATIONS: 'azavCertifications',
  CONTACT_PERSON: 'contactPerson',
  CONTACT_PERSONS: 'contactPersons',
  COMPANY: 'company',
  COMPANIES: 'companies',
  COURSE: 'course',
  COURSES: 'courses',
  COURSE_TYPE: 'courseType',
  COURSE_TYPES: 'courseTypes',
  COURSE_STATUS: 'courseStatus',
  COURSE_STATUSES: 'courseStatuses',
  ENROLLMENT: 'enrollment',
  ENROLLMENTS: 'enrollments',
  FEE: 'fee',
  FEES: 'fees',
  LECTURER: 'lecturer',
  LECTURERS: 'lecturers',
  PARTICIPANT: 'participant',
  PARTICIPANTS: 'participants',
  PARTICIPANT_STATUS: 'participantStatus',
  PARTICIPANT_STATUSES: 'participantStatuses',
  QUALIFICATION: 'qualification',
  QUALIFICATIONS: 'qualifications',
  ROOM: 'room',
  ROOMS: 'rooms',
  SKILL: 'skill',
  SKILLS: 'skills',
  SPONSOR: 'sponsor',
  SPONSORS: 'sponsors',
  SUBJECT: 'subject',
  SUBJECTS: 'subjects',
  TEMPLATE: 'template',
  TEMPLATES: 'templates',
  TARGET_GROUP: 'targetGroup',
  TARGET_GROUPS: 'targetGroups',
  TEACHING_FORMAT: 'teachingFormat',
  TEACHING_FORMATS: 'teachingFormats',
  TIMETABLE_ENTRY: 'timetableEntry',
  TIMETABLE_ENTRIES: 'timetableEntries',
  INVOICE_RECIPIENT: 'invoiceRecipient',
  INVOICE_RECIPIENTS: 'invoiceRecipients',
};

export default Bivema;
