import React from 'react';
import { Badge } from 'react-bootstrap';
import { initialSearchTerm } from './PagedTableSearch';

/** The StatusBadge component displays the status of the table.
 * If a search term is set, it displays the search term and the number of results.
 * If no search term is set, it displays the number of total elements.
 * @param {string} searchTerm - the current search term state
 * @param {number} totalElements - the total number of elements
 * @param {number} totalPages - the total number of pages
 * @param {boolean} loading - the current loading state
 */

const StatusBadge = ({
  searchTerm, totalElements, totalPages, loading,
}) => {
  if (loading) {
    return (
      <Badge pill bg="warning" className="pb-2">
        Lade...
      </Badge>
    );
  }

  if (searchTerm !== initialSearchTerm) {
    return (
      <Badge pill bg="primary" className="pb-2">
        Gefiltert nach
        {' "'}
        {searchTerm}
        {'" | Insgesamt '}
        {totalElements}
        {' auf '}
        {totalPages}
        {' Seite(n)'}
      </Badge>
    );
  }

  // Loading Seiten if search term different to last one

  return (
    <Badge pill bg="secondary" className="pb-2">
      Alle Einträge
      {' | Insgesamt '}
      {totalElements}
      {' auf '}
      {totalPages}
      {' Seite(n)'}
    </Badge>
  );
};

export default StatusBadge;
