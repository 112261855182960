import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import SingleSelect from '../../components/form/selects/SingleSelect';
import PagedTable from '../../components/table/PagedTable';
import { Renderers } from '../../components/table/Renderer';
import Bivema from '../../common/Bivema';
import getAllPagedForSelect from '../../api/GetAllPagedForSelect';
import { getSelfLink } from '../../utils/Utils';

const columnsEnrollments = [
  {
    title: 'Bildungsveranstaltung',
    name: 'courseNumber',
    relation: Bivema.COURSE,
  },
  {
    title: 'Bestellnummer',
    name: 'orderNumber',
  },
  {
    title: 'Teilnehmer',
    name: Bivema.PARTICIPANT,
    relation: Bivema.PARTICIPANT,
    renderer: Renderers.FullName,
  },
  {
    title: 'TN-Status',
    name: 'status',
    relation: Bivema.PARTICIPANT_STATUS,
  },
  {
    title: 'Rechnungsempfänger',
    name: Bivema.INVOICE_RECIPIENT,
    relation: Bivema.INVOICE_RECIPIENT,
    renderer: Renderers.InvoiceRecipientsWithCompany,
  },
  {
    title: 'Kostenträger',
    name: 'description',
    relation: Bivema.SPONSOR,
  },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultEnrollment = {
  course: '',
  participant: '',
  participantStatus: '',
  orderNumber: '',
  invoiceRecipient: '',
  sponsor: '',
};
const enrollmentValidationSchema = Yup.object().shape({
  course: Yup.string()
    .required('Bitte eine BV auswählen.'),
  participant: Yup.string()
    .required('Bitte einen Teilnehmer auswählen.'),
  participantStatus: Yup.string()
    .nullable(true),
  orderNumber: Yup.string()
    .nullable(true),
  invoiceRecipient: Yup.string()
    .nullable(true),
  sponsor: Yup.string()
    .nullable(true),
});

const InvoiceRecipientSelectLabel = (invoiceRecipient) => {
  return (
    `${invoiceRecipient.firstName ? invoiceRecipient.firstName : ''}
    ${invoiceRecipient.lastName ? invoiceRecipient.lastName : ''}
    ${invoiceRecipient.email ? `(${invoiceRecipient.email})` : ''}
    ${(invoiceRecipient.firstName || invoiceRecipient.lastName || invoiceRecipient.email) && invoiceRecipient.company ? ' | ' : ''}
    ${invoiceRecipient.company && invoiceRecipient.company.name ? invoiceRecipient.company.name : ''}`
  );
};

const Enrollments = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [courses, setCourses] = useState();
  const [participants, setParticipants] = useState();
  const [participantStatuses, setParticipantStatuses] = useState();
  const [invoiceRecipients, setInvoiceRecipients] = useState();
  const [sponsors, setSponsors] = useState();
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');

  const [enrollment, setEnrollment] = useState({
    ...defaultEnrollment,
  });

  const handleSetEnrollment = (value) => {
    const editEnrollment = {
      ...value,
      participant: getSelfLink(value.participant),
      course: getSelfLink(value.course),
      invoiceRecipient: getSelfLink(value.invoiceRecipient),
      sponsor: getSelfLink(value.sponsor),
      participantStatus: getSelfLink(value.participantStatus),
    };

    setEnrollment(editEnrollment);
  };

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  // set Tab Title
  useEffect(() => {
    document.title = 'Anmeldung | Verwaltung';
  }, []);

  // update participant
  useEffect(() => {
    const getParticipants = async () => {
      const response = await getAllPagedForSelect('participants');
      const result = response._embedded?.participants
        .map((participant) => ({
          label: `${participant.firstName} ${participant.lastName}`,
          value: participant._links.participant.href,
        }));
      setParticipants(result);
    };

    if (participants === undefined) {
      getParticipants();
    }
  }, []);

  // update course
  useEffect(() => {
    const getCourses = async () => {
      const response = await getAllPagedForSelect('courses');
      const result = response._embedded?.courses
        .map((course) => ({
          label: course.courseNumber,
          value: course._links.course.href,
        }));
      setCourses(result);
    };

    if (courses === undefined) {
      getCourses();
    }
  }, []);

  // update invoiceRecipient
  useEffect(() => {
    const getInvoiceRecipients = async () => {
      const response = await getAllPagedForSelect('invoiceRecipients');
      const result = response._embedded?.invoiceRecipients
        .map((invoiceRecipient) => ({
          label: InvoiceRecipientSelectLabel(invoiceRecipient),
          value: invoiceRecipient._links.invoiceRecipient.href,
        }));
      setInvoiceRecipients(result);
    };

    if (invoiceRecipients === undefined) {
      getInvoiceRecipients();
    }
  }, []);

  // update sponsor
  // here we can get all because there are not many
  useEffect(() => {
    const getSponsors = async () => {
      const response = await getAllPagedForSelect('sponsors');
      const result = response._embedded?.sponsors
        .map((sponsor) => ({
          label: sponsor.description,
          value: sponsor._links.sponsor.href,
        }));
      setSponsors(result);
    };

    if (sponsors === undefined) {
      getSponsors();
    }
  }, []);

  // update participantStatus
  // here we can get all because there are not many
  useEffect(() => {
    const getParticipantStatuses = async () => {
      const response = await getAllPagedForSelect('participantStatuses');
      const result = response._embedded?.participantStatuses
        .map((participantStatus) => ({
          label: participantStatus.status,
          value: participantStatus._links.participantStatus.href,
        }));
      setParticipantStatuses(result);
    };

    if (participantStatuses === undefined) {
      getParticipantStatuses();
    }
  }, []);

  useEffect(() => {
    if (enrollment._links !== undefined) {
      setSelfLink(enrollment._links.self.href);
    }

    if (formikRef.current && enrollment !== formikRef.current.values) {
      formikRef.current.setValues(enrollment);
    }
  }, [enrollment]);

  return (
    <div className="container-fluid">
      <ButtonCard title="Anmeldung" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Anmeldung"
          setEntity={setEnrollment}
          defaultEntity={defaultEnrollment}
          settersToReset={[setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={enrollment}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.ENROLLMENTS,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={enrollmentValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values, setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} onReset={handleReset} noValidate>
                    <Modal.Body>
                      <Form.Group controlId="enrollment-group">
                        <SingleSelect
                          name="course"
                          placeholder="Bildungsveranstaltung*"
                          // pass object to defaultValue, so it be displayed in the select
                          value={courses?.find((a) => a.value === values.course)}
                          values={courses}
                          setFieldValue={setFieldValue}
                          touched={touched.course}
                          errorDescription={errors.course}
                        />
                        <SingleSelect
                          name="participant"
                          placeholder="Teilnehmer*"
                          // pass object to defaultValue, so it be displayed in the select
                          value={participants?.find((a) => a.value === values.participant)}
                          values={participants}
                          setFieldValue={setFieldValue}
                          touched={touched.participant}
                          errorDescription={errors.participant}
                        />
                        <SingleSelect
                          name="participantStatus"
                          placeholder="Anmeldungs-Status"
                          // pass object to defaultValue, so it be displayed in the select
                          value={participantStatuses?.find((a) => a.value
                            === values.participantStatus)}
                          values={participantStatuses}
                          setFieldValue={setFieldValue}
                          touched={touched.participant}
                          errorDescription={errors.participant}
                        />
                        <InputField
                          value={values.orderNumber}
                          handleChange={handleChange}
                          touched={touched.orderNumber}
                          errorDescription={errors.orderNumber}
                          fieldName="orderNumber"
                          placeholder="Bestellnummer"
                          props={{ className: 'mb-2' }}
                        />
                        <SingleSelect
                          name="invoiceRecipient"
                          placeholder="Rechnungsempfänger"
                          // pass object to defaultValue, so it be displayed in the select
                          value={invoiceRecipients?.find((a) => a.value
                            === values.invoiceRecipient)}
                          values={invoiceRecipients}
                          setFieldValue={setFieldValue}
                          touched={touched.invoiceRecipient}
                          errorDescription={errors.invoiceRecipient}
                        />
                        <SingleSelect
                          name="sponsor"
                          placeholder="Kostenträger"
                          // pass object to defaultValue, so it be displayed in the select
                          value={sponsors?.find((a) => a.value === values.sponsor)}
                          values={sponsors}
                          setFieldValue={setFieldValue}
                          touched={touched.sponsor}
                          errorDescription={errors.sponsor}
                        />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <ButtonGroup
                        handleDelete={() => handleDelete(selfLink, setUpdate)}
                        selfLink={selfLink !== undefined}
                        setShowDynModal={setShowDynModal}
                        setClassNameDynModal={setClassNameDynModal}
                      />
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
              )}
        />
        <PagedTable
          entityName={Bivema.ENROLLMENTS}
          columns={columnsEnrollments}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={handleSetEnrollment}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default Enrollments;
