import React from 'react';
import { Modal } from 'react-bootstrap';
import ModalTitle from 'react-bootstrap/ModalTitle';

/**
 * A modal whose width can be changed via modalSize.
 * This is implemented via the bootstrap breakpoints e.g. md
 *
 * @param {*} className ClassName of the Modal
 * @param {*} headerTitle Header Title of the Modal
 * @param {*} modalBody Body Component of the Modal
 * @param {*} setEntity Function to set the entity from parent component
 * @param {*} defaultEntity Default entity to reset the form
 * @param {*} settersToReset All setters that should be reset
 * @param {*} formikRef Formik reference to reset the form
 * @param {*} setShow To set show to true or false
 * @param {*} show To Show or hide the Modal
 * @param {*} modalSize Size of the modal, default 'md'
 *
 * @return {*}
 */
const DynModal = ({
  headerTitle,
  modalBody,
  setEntity,
  defaultEntity,
  formikRef,
  settersToReset,
  setShow,
  show,
  modalSize = 'md',
}) => {
  return (
    <Modal
      className="dynModal"
      size={modalSize}
      show={show}
      onHide={() => setShow(false)}
      onExited={() => {
        formikRef.current.resetForm();
        setEntity(defaultEntity);
        if (settersToReset !== undefined) {
          settersToReset.forEach((setter) => {
            setter(undefined);
          });
        }
        setShow(false);
      }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <ModalTitle as="h5" className="realtime-rounded">
          {headerTitle}
          {' '}
          anlegen
        </ModalTitle>
      </Modal.Header>
      {modalBody}
    </Modal>
  );
};

export default DynModal;
