import React from 'react';
import { toast } from 'react-toastify';
import './Toast.css';
import iconSuccess from '../../assets/modo-icons/Icons/svg/coding_button_success.svg';

export default function SuccessToast(text = 'Erfolg') {
  toast.success(
    <div className="toast-message">
      {text}
    </div>,
    { icon: () => <img src={iconSuccess} alt={text} className="svg-portfolio svg-icon" /> },
  );
}
