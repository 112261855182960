import SuccessToast from '../components/toasts/SuccessToast';
import KeycloakUtil from '../utils/KeycloakUtil/KeycloakUtil';

async function postData(urlEntity, insertEntity) {
  try {
    const authorization = `Bearer ${KeycloakUtil.getToken()}`;
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const response = await fetch(apiBaseUrl + urlEntity, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
      body: JSON.stringify(insertEntity),
    });
    const errorResponse = await response.json();
    if (response.ok) {
      SuccessToast('Speichern erfolgreich');
    } else {
      throw new Error(JSON.stringify(errorResponse));
    }
  } catch (error) {
    return error;
  }
  return null;
}
export default postData;
