/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import StatusBadge from './StatusBadge';
import { logger } from '../../wrapper/Logger';
import Paginator from './Paginator';
import PagedTableRow from './PagedTableRow';
import PagedTableSearch, { initialSearchTerm } from './PagedTableSearch';
import { buildTableData, buildTableDataSearch } from './TableData';
import { Renderers } from './Renderer';

const columnsDefault = [
  { title: 'Id', name: 'id' },
];
/** The PagedTable component is a table with pagination and search.
 * It is used to display a list of entities from a HAL API.
 * It also supports rendering relations of the entities.
 * @param {string} entityName - the name of the entity, e.g. 'addresses'
 * @param {int} pageSize - the number of items to be shown per page.
 * pageSize can currently not be changed.
 * @param {object[]} columns - definition of columns to be shown in the table
 * with a display name of the column, the name of the data attribute and
 * the optional name of the renderer to be used (see ColumnRenderer.js).
 * If you set a relation, the relation will be resolved via a call
 * to the according api via the links.
 * Expected data format:
* [
  { title: 'Name', data: 'name' },
  {
    title: 'Bildungsveranstaltung',
    name: 'courseNumber',
    relation: 'course',
  },
  {
    title: 'Adresse',
    name: 'addresses',
    renderer: Renderers.Address,
  },
  { title: 'Edit', data: '_links.self.href' },
]
 * @param {boolean} search - whether to show the search bar or not
 * @returns {JSX.Element} - the table with pagination and search
 */
const PagedTable = ({
  entityName,
  pageSize,
  columns = columnsDefault,
  search = true,
  setShowDynModal,
  setEntity,
  update: loading,
  setUpdate: setLoading,
}) => {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  // this is the term that is set when the search button is clicked/enter pressed
  // and criteria are met, provided by the PagedTableSearch component
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  // The useRef Hook allows us to persist data between renders
  const prevSearchTermRef = useRef(initialSearchTerm);

  const switchPage = (page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      setTableData([]);
    }
  };

  // reset only table data, not paging
  const resetTableData = () => {
    setTableData([]);
  };

  // reset paging
  const resetPaging = () => {
    setCurrentPage(0);
    setTotalElements(0);
    setTotalPages(0);
  };

  // reset search is passed to PagedTableSearch
  // and is called when the reset button is clicked
  // or the search term is reset
  // to avoid calling useEffect twice, the resetSearch function
  // bundles the reset of search term, the table data and the paging
  const resetSearch = () => {
    resetTableData();
    setSearchTerm(initialSearchTerm);
    setCurrentPage(0);
    setTotalElements(0);
    setTotalPages(0);
  };

  // get new data (without search) when paging changes or after searchTerm resets
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const daten = await buildTableData(entityName, currentPage, pageSize);
        setTableData(daten.tableData);
        setTotalPages(daten.page.totalPages);
        setTotalElements(daten.page.totalElements);
        setLoading(false);
      } catch (error) {
        logger.error(error);
      }
    };

    // if the searchTerm is the initialSearchTerm, the searchTerm was reset
    // via resetSearch, so fetch new data
    if (searchTerm === initialSearchTerm) {
      fetchTableData();
    }
  }, [currentPage, searchTerm, loading]);

  // get new data when searchTerm or currentPage changes, but only if there is a searchTerm
  // and reset paging when searchTerm changes
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const daten = await buildTableDataSearch(
          entityName,
          searchTerm,
          currentPage,
          pageSize,
        );
        setTableData(daten.tableData);
        setTotalPages(daten.page.totalPages);
        setTotalElements(daten.page.totalElements);
        setLoading(false);
      } catch (error) {
        logger.error(error);
      }
    };

    // if the searchTerm is not the previos searchTerm
    if (searchTerm !== prevSearchTermRef.current) {
      // if the searchTerm was changed and page isn't zero, reset paging
      // setting the paging will re-trigger this useEffect
      if (currentPage !== 0) {
        resetPaging();
      }

      // remember the current searchTerm for next render
      prevSearchTermRef.current = searchTerm;
    }

    // only if the searchTerm is not empty string
    if (searchTerm !== initialSearchTerm) {
      // if the searchTerm is the same as the previous one and not initial
      // only the page was changed, so fetch new data
      resetTableData();
      fetchTableData();
    }
  }, [currentPage, searchTerm, loading]);

  return (
    <div>
      {/* only show PagedTableSearch if search is true */}
      {
        search && (
        <PagedTableSearch
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          resetSearch={resetSearch}
          loading={loading}
        />
        )
      }
      <div className="fw-normal realtime-rounded fs-5">
        <StatusBadge
          searchTerm={searchTerm}
          totalElements={totalElements}
          totalPages={totalPages}
          loading={loading}
        />
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        (tableData === undefined || tableData.length === 0) ? (
          <div className="text-center">
            <p>Keine Daten vorhanden.</p>
          </div>
        ) : (
          <Table responsive striped hover>
            <thead>
              <tr>
                {columns.map((column) => (
                  // only show column if renderer is not Hide
                  column.renderer !== Renderers.Hide
                  && <th key={column.name}>{column.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((rowData) => (
                <PagedTableRow
                  key={rowData._links?.self?.href}
                  columns={columns}
                  rowData={rowData}
                  setShowDynModal={setShowDynModal}
                  setEntity={setEntity}
                />
              ))}
            </tbody>
          </Table>
        )
      )}
      <Paginator
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={switchPage}
      />
    </div>
  );
};

export default PagedTable;
