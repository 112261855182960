// eslint no-console is disable, because we handle the logging in the logger
/* eslint-disable no-console */

// get log level from .env file
const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL;
const NO_OP = () => {};

/** Logger which outputs to the browser console */
export class ConsoleLogger {
  constructor(options) {
    const { level } = options || {};

    // no log level is set, so we don't log anything
    if (level === '') {
      this.warn = NO_OP;
      this.error = NO_OP;
      this.log = NO_OP;

      return;
    }

    this.error = console.error.bind(console);

    // if the log level is set to error, we only log errors
    if (level === 'error') {
      this.warn = NO_OP;
      this.log = NO_OP;

      return;
    }

    this.warn = console.warn.bind(console);

    // if the log level is set to warn, we only log errors and warnings
    if (level === 'warn') {
      this.log = NO_OP;

      return;
    }

    // otherwise we log everything
    this.log = console.log.bind(console);
  }
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL });
