import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import Validation from '../../utils/Validation';
import ValidationErrors from '../../utils/ValidationErrors';
import SingleSelect from '../../components/form/selects/SingleSelect';
import CheckBox from '../../components/form/Checkbox';
import MultiSelect from '../../components/form/selects/MultiSelect';
import PagedTable from '../../components/table/PagedTable';
import { Renderers } from '../../components/table/Renderer';
import Bivema from '../../common/Bivema';
import getAllPagedForSelect from '../../api/GetAllPagedForSelect';
import { getSelfLinkFromArrayObject, getSelfLink } from '../../utils/Utils';

const columnsLecturers = [
  { title: 'Nachname', name: 'lastName' },
  { title: 'Vorname', name: 'firstName' },
  { title: 'E-Mail', name: 'email' },
  { title: 'Mobil', name: 'mobile' },
  { title: 'Telefon', name: 'phone' },
  { title: 'Geburtstag', name: 'birthdate', renderer: Renderers.Date },
  { title: 'Extern', name: 'external', renderer: Renderers.Boolean },
  {
    title: 'Adresse',
    name: Bivema.ADDRESS,
    relation: Bivema.ADDRESS,
    renderer: Renderers.Address,
  },
  {
    title: 'Skills',
    name: Bivema.SKILLS,
    relation: Bivema.SKILLS,
    renderer: Renderers.Hide,
  },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultLecturer = {
  firstName: '',
  lastName: '',
  email: '',
  emailAcademy: '',
  phone: '',
  mobile: '',
  birthdate: '',
  external: false,
  address: '',
  certifications: '',
  skills: [],
};
const lecturerValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable(true)
    .max(255, 'Darf maximal 255 Zeichen lang sein.'),
  lastName: Yup.string()
    .max(255, 'Darf maximal 255 Zeichen lang sein.')
    .required('Bitte geben Sie einen Nachnamen ein.'),
  email: Yup.string()
    .required('Bitte geben Sie eine Email ein.')
    .max(100, 'Darf maximal 100 Zeichen lang sein.')
    .matches(Validation.email, ValidationErrors.email),
  emailAcademy: Yup.string()
    .nullable(true)
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .matches(Validation.email, ValidationErrors.email),
  phone: Yup.string()
    .nullable(true)
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .matches(Validation.tel, ValidationErrors.tel),
  mobile: Yup.string()
    .nullable(true)
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .matches(Validation.tel, ValidationErrors.tel),
  birthdate: Yup.date()
    .nullable(true),
  external: Yup.bool(),
  address: Yup.string()
    .nullable(true),
  certifications: Yup.string()
    .nullable(true)
    .max(50, 'Darf maximal 50 Zeichen lang sein.'),
  skills: Yup.array()
    .nullable(true),
});

const LecturersOverview = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [addresses, setAddresses] = useState();
  const [skills, setSkills] = useState();
  const [skillValues, setSkillValues] = useState([]);
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');

  const [lecturer, setLecturer] = useState({
    ...defaultLecturer,
  });

  const handleSetLecturer = (value) => {
    const editLecturer = {
      ...value,
      address: getSelfLink(value.address),
      skills: getSelfLinkFromArrayObject(value.skills),
    };

    setLecturer(editLecturer);
  };

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  // set Tab Title
  useEffect(() => {
    document.title = 'Dozent | Verwaltung';
  }, []);

  // update addresses
  useEffect(() => {
    const getAddresses = async () => {
      const response = await getAllPagedForSelect('addresses');
      const result = response._embedded?.addresses
        .map((address) => ({ label: `${address.street} ${address.houseNumber}, ${address.city}`, value: address._links.address.href }));
      setAddresses(result);
    };

    if (addresses === undefined) {
      getAddresses();
    }
  }, []);

  // update skills
  useEffect(() => {
    const getSkills = async () => {
      const response = await getAllPagedForSelect('skills');
      const result = response._embedded?.skills
        .map((skill) => ({
          label: skill.thema,
          value: skill._links.skill.href,
        }));
      setSkills(result);
    };

    if (skills === undefined) {
      getSkills();
    }
  }, []);

  useEffect(() => {
    if (lecturer._links !== undefined) {
      setSelfLink(lecturer._links.self.href);
    }

    // sets formik values
    if (formikRef.current && lecturer !== formikRef.current.values) {
      formikRef.current.setValues(lecturer);
    }

    // sets skill values for multi select
    // compares the skills of the lecturer with the skills from the api
    // if the skill is in the lecturer, it will be added to the skillValues
    if (skills !== undefined && skills.length > 0
      && lecturer.skills !== undefined) {
      const values = skills.filter((skill) => lecturer.skills.includes(skill.value));
      setSkillValues(values);
    }
  }, [lecturer]);

  return (
    <div className="container-fluid">
      <ButtonCard title="Dozent" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Dozent"
          setEntity={setLecturer}
          defaultEntity={defaultLecturer}
          settersToReset={[setSkillValues, setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalSize="lg"
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={lecturer}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.LECTURERS,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={lecturerValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values, setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} onReset={handleReset} noValidate>
                    <Modal.Body>
                      <Form.Group controlId="lecturer-group">
                        <Container>
                          <Row>
                            <Col md={6}>
                              <InputField
                                value={values.lastName}
                                handleChange={handleChange}
                                touched={touched.lastName}
                                errorDescription={errors.lastName}
                                fieldName="lastName"
                                placeholder="Nachname*"
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                            <Col md={6}>
                              <CheckBox
                                checked={values.external}
                                fieldname="external"
                                label="Extern"
                                handleChange={handleChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <InputField
                                value={values.firstName}
                                handleChange={handleChange}
                                touched={touched.firstName}
                                errorDescription={errors.firstName}
                                fieldName="firstName"
                                placeholder="Vorname"
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                            <Col md={6}>
                              <InputField
                                value={values.emailAcademy}
                                handleChange={handleChange}
                                touched={touched.emailAcademy}
                                errorDescription={errors.emailAcademy}
                                fieldName="emailAcademy"
                                placeholder="Email Academy"
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <SingleSelect
                                name="address"
                                placeholder="Adresse"
                                // pass object to defaultValue, so it be displayed in the select
                                value={addresses?.find((a) => a.value === values.address)}
                                values={addresses}
                                setFieldValue={setFieldValue}
                                touched={touched.address}
                                errorDescription={errors.address}
                              />
                            </Col>
                            <Col md={6}>
                              <InputField
                                value={values.birthdate}
                                handleChange={handleChange}
                                type="date"
                                touched={touched.birthdate}
                                errorDescription={errors.birthdate}
                                fieldName="birthdate"
                                placeholder="Geburtstag"
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <InputField
                                value={values.phone}
                                handleChange={handleChange}
                                touched={touched.phone}
                                errorDescription={errors.phone}
                                fieldName="phone"
                                placeholder="Telefon"
                              />
                            </Col>
                            <Col md={6}>
                              <InputField
                                value={values.certifications}
                                handleChange={handleChange}
                                touched={touched.certifications}
                                errorDescription={errors.certifications}
                                fieldName="certifications"
                                placeholder="Zertifizierung"
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <InputField
                                value={values.mobile}
                                handleChange={handleChange}
                                touched={touched.mobile}
                                errorDescription={errors.mobile}
                                fieldName="mobile"
                                placeholder="Mobil"
                              />
                            </Col>
                            <Col md={6}>
                              <MultiSelect
                                name="skills"
                                placeholder="Skills"
                                options={skills}
                                values={skillValues}
                                setTemplateValues={setSkillValues}
                                setFieldValue={setFieldValue}
                                touched={touched.skills}
                                errorDescription={errors.skills}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <InputField
                                value={values.email}
                                handleChange={handleChange}
                                touched={touched.email}
                                errorDescription={errors.email}
                                fieldName="email"
                                placeholder="Email*"
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <ButtonGroup
                        handleDelete={() => handleDelete(selfLink, setUpdate)}
                        selfLink={selfLink !== undefined}
                        setShowDynModal={setShowDynModal}
                        setClassNameDynModal={setClassNameDynModal}
                      />
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
              )}
        />
        <PagedTable
          entityName={Bivema.LECTURERS}
          columns={columnsLecturers}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={handleSetLecturer}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default LecturersOverview;
