/* This file is used to style the react-select component.
 * Unfortunately we didn't find a way to use our existing stylesheets.
 * This is why you have to change the colors here.
 * If you find a way to use the existing stylesheets, please change it!
 *
 * Description in ISSUE-88
*/
function borderValid(state) {
  return {
    border: 'var(--bs-border-width) solid var(--bs-form-valid-border-color)',
    boxShadow: state.isFocused
      ? '0 0 0 .25rem rgba(var(--bs-success-rgb),.25)'
      : 'none',
    '&:hover': {
      border: 'var(--bs-border-width) solid var(--bs-form-valid-border-color)',
    },
  };
}

function borderInvalid(state) {
  return {
    border: 'var(--bs-border-width) solid var(--bs-form-invalid-border-color)',
    boxShadow: state.isFocused
      ? '0 0 0 .25rem rgba(var(--bs-danger-rgb),.25)'
      : 'none',
    '&:hover': {
      border: 'var(--bs-border-width) solid var(--bs-form-invalid-border-color)',
    },
  };
}

function borderNormal(state) {
  return {
    border: state.isFocused
      ? 'var(--bs-border-width) solid var(--bs-primary-border-subtle)'
      : 'var(--bs-border-width) solid var(--bs-border-color)',
    boxShadow: state.isFocused
      ? '0 0 0 .25rem rgba(13,110,253,.25)'
      : 'none',
    '&:hover': {
      border: state.isFocused
        ? 'var(--bs-border-width) solid var(--bs-primary-border-subtle)'
        : 'var(--bs-border-width) solid var(--bs-border-color)',
    },
  };
}

export function borderColor(state, error, touched) {
  // isValid
  if (touched && !error) {
    return borderValid(state);
  }

  // isInValid
  if (error !== undefined) {
    return borderInvalid(state);
  }

  return borderNormal(state);
}

export const selectMenuToFront = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};

export const placeholderColor = {
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: 'inherit',
  }),
};
