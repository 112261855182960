// This is an adapted version of the MIT-Licensed
// https://github.com/dasniko/keycloak-reactjs-demo UserService.js file.
// See License in ./LICENSE

import Keycloak from 'keycloak-js';

const liveDomain = 'bivema.mischok.academy';
const stagingDomain = 'bivema-staging.mischok.academy';

const _kc = new Keycloak({
  realm: (window.location.hostname === liveDomain || window.location.hostname === stagingDomain) ? 'master' : 'test',
  url: 'https://auth.mischok.de/auth',
  clientId: 'bivema',
});

/**
 * Retuns a Promise that Initializes Keycloak instance and authenticates the user.
 *
 * It does use onLoad: 'check-sso' and calls login if not authentificated,
 * because the more fitting option 'login-required' has a problem with tracking protection in
 * firefox that causes continuos page reloads.
 */
async function initKeycloak() {
  return _kc.init({
    onLoad: 'check-sso',
    checkLoginIframe: false,
    enableLogging: true,
  }).then((authenticated) => {
    if (!authenticated) {
      _kc.login();
    }
  });
}

const getUserData = () => _kc.idTokenParsed;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

async function updateToken() {
  return _kc.updateToken()
    .catch(_kc.login);
}

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const KeycloakUtil = {
  initKeycloak,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  hasRole,
  getUserData,
};

export default KeycloakUtil;
