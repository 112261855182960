import { Button, Modal, Stack } from 'react-bootstrap';

/**
 * A modal to confirm the deletion before deleting
 * This is implemented via the react-bootstrap breakpoints
 *
 * @param {*} showConfModal To Show or hide the Confirmation Modal
 * @param {*} setShowConfModal To set show of the Confirmation Modal to true or false
 * @param {*} handleDelete The function to delete the element in the database
 * @param {*} setShowDynModal To set show of the DynModal to true or false
 * @param {*} setClassNameDynModal To set the className of the DynModal
 *
 * @return {*}
 */

function ConfimationModal({
  showConfModal,
  setShowConfModal,
  handleDelete,
  setShowDynModal,
  setClassNameDynModal,
}) {
  // setClassName for the DynModal to show the DynModal ("reset" the opacity from 0.0 in the CSS)
  const handleClose = () => {
    setShowConfModal(false);
    setClassNameDynModal('dynModal');
  };

  const handleOnHide = () => {
    setShowConfModal(false);
    setClassNameDynModal('dynModal');
  };

  const handleOnDelete = () => {
    handleDelete();
    setClassNameDynModal('dynModal');
    setShowDynModal(false);
  };

  return (
    <Modal
      show={showConfModal}
      onHide={handleOnHide}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="confirmation-modal-id" />
      </Modal.Header>
      <Modal.Body className="confirmation-modal-body">
        Wollen Sie dieses Element wirklich Löschen ?
      </Modal.Body>
      <Modal.Footer className="confirmation-modal-footer">
        <Stack gap={5} direction="horizontal">
          <Button variant="danger" className="confirmation-modal-button btn btn-danger bg-warning border-0 text-white text-uppercase" onClick={handleOnDelete}>Ja</Button>
          <Button className="confirmation-modal-button btn btn-secondary bg-panther border-0 text-white text-uppercase" onClick={handleClose}>Nein</Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfimationModal;
