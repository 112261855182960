import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Col,
  Container, Form, Modal, Row,
} from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';

const columnsAddresses = [
  { title: 'Strasse', name: 'street' },
  { title: 'Hausnummer', name: 'houseNumber' },
  { title: 'Plz', name: 'postalCode' },
  { title: 'Ort', name: 'city' },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultAddress = {
  postalCode: '', city: '', street: '', houseNumber: '',
};

const addressValidationSchema = Yup.object().shape({
  street: Yup.string()
    .max(100, 'Darf maximal 100 Zeichen lang sein.')
    .required('Bitte geben Sie eine Straße ein.'),
  houseNumber: Yup.string()
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .required('Bitte geben Sie eine Hausnummer ein.'),
  postalCode: Yup.string()
    .max(5, 'Darf maximal 5 Zeichen lang sein.')
    .required('Bitte geben Sie eine PLZ ein.'),
  city: Yup.string()
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .required('Bitte geben Sie einen Ort ein.'),
});

const AddressOverview = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');

  const [address, setAddress] = useState({
    ...defaultAddress,
  });

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  // set Tab Title
  useEffect(() => {
    document.title = 'Adresse | Stammdaten';
  }, []);

  useEffect(() => {
    if (address._links !== undefined) {
      setSelfLink(address._links.self.href);
    }

    // sets formik values
    if (formikRef.current && address !== formikRef.current.values) {
      formikRef.current.setValues(address);
    }
  }, [address]);

  return (
    <div className="container-fluid">
      <ButtonCard title="Adresse" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Adresse"
          setEntity={setAddress}
          defaultEntity={defaultAddress}
          settersToReset={[setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalBody={(
            <Formik
              innerRef={formikRef}
              initialValues={address}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.ADDRESSES,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={addressValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values,
              }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                  <Modal.Body>
                    <Form.Group controlId="address-group">
                      <Container>
                        <Row>
                          <Col xs="8">
                            <InputField
                              value={values.street}
                              handleChange={handleChange}
                              touched={touched.street}
                              errorDescription={errors.street}
                              fieldName="street"
                              placeholder="Straße*"
                              props={{ className: 'mb-2' }}
                            />
                          </Col>
                          <Col>
                            <InputField
                              value={values.houseNumber}
                              handleChange={handleChange}
                              touched={touched.houseNumber}
                              errorDescription={errors.houseNumber}
                              fieldName="houseNumber"
                              placeholder="HsNr.*"
                              props={{ className: 'mb-2' }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <InputField
                              value={values.postalCode}
                              handleChange={handleChange}
                              touched={touched.postalCode}
                              errorDescription={errors.postalCode}
                              fieldName="postalCode"
                              placeholder="PLZ*"
                              props={{ className: 'mb-2' }}
                            />
                          </Col>
                          <Col xs="9">
                            <InputField
                              value={values.city}
                              handleChange={handleChange}
                              touched={touched.city}
                              errorDescription={errors.city}
                              fieldName="city"
                              placeholder="Ort*"
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonGroup
                      handleDelete={() => handleDelete(selfLink, setUpdate)}
                      selfLink={selfLink !== undefined}
                      setShowDynModal={setShowDynModal}
                      setClassNameDynModal={setClassNameDynModal}
                    />
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
      )}
        />
        <PagedTable
          entityName={Bivema.ADDRESSES}
          columns={columnsAddresses}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={setAddress}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default AddressOverview;
