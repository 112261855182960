import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import SingleSelect from '../../components/form/selects/SingleSelect';
import MultiSelect from '../../components/form/selects/MultiSelect';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';
import { Renderers } from '../../components/table/Renderer';
import getAllPagedForSelect from '../../api/GetAllPagedForSelect';
import { getSelfLinkFromArrayObject, getSelfLink } from '../../utils/Utils';

const columnsCourses = [
  { title: 'BV-Nr.', name: 'courseNumber' },
  {
    title: 'BV-Art',
    name: 'description',
    relation: Bivema.COURSE_TYPE,
  },
  {
    title: 'Start',
    name: 'startDate',
    renderer: Renderers.Date,
  },
  {
    title: 'Ende',
    name: 'endDate',
    renderer: Renderers.Date,
  },
  {
    title: 'Status',
    name: 'description',
    relation: Bivema.COURSE_STATUS,
  },
  {
    title: 'Templates',
    name: Bivema.TEMPLATES,
    relation: Bivema.TEMPLATES,
    renderer: Renderers.Templates,
  },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultCourse = {
  courseNumber: '',
  courseType: '',
  startDate: '',
  endDate: '',
  courseStatus: '',
  templates: [],
};

const courseValidationSchema = Yup.object().shape({
  courseNumber: Yup.string()
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .required('Bitte geben Sie eine Bezeichnung ein.'),
  courseType: Yup.string()
    .required('Bitte geben Sie eine BV-Art ein.'),
  startDate: Yup.date()
    .nullable(true),
  endDate: Yup.date().min(
    Yup.ref('startDate'),
    'Das Enddatum darf nicht vor dem Startdatum liegen',
  )
    .nullable(true),
  courseStatus: Yup.string()
    .nullable(true),
  templates: Yup.array()
    .nullable(true),
});

const CourseOverview = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [courseTypes, setCourseTypes] = useState();
  const [courseStatuses, setCourseStatuses] = useState();
  const [templates, setTemplates] = useState();
  const [templateValues, setTemplateValues] = useState([]);
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');

  const [course, setCourse] = useState({
    ...defaultCourse,
  });

  const handleSetCourse = (value) => {
    const editCourse = {
      ...value,
      courseType: getSelfLink(value.courseType),
      courseStatus: getSelfLink(value.courseStatus),
      templates: getSelfLinkFromArrayObject(value.templates),
    };

    setCourse(editCourse);
  };

  // update courseTypes
  useEffect(() => {
    const getCourseTypes = async () => {
      const response = await getAllPagedForSelect('courseTypes');
      const result = response._embedded?.courseTypes
        .map((courseType) => ({
          label: courseType.description,
          value: courseType._links.courseType.href,
        }));

      setCourseTypes(result);
    };

    if (courseTypes === undefined) {
      getCourseTypes();
    }
  }, []);

  // update courseStatuses
  useEffect(() => {
    const getCourseStatuses = async () => {
      const response = await getAllPagedForSelect('courseStatuses');
      const result = response._embedded?.courseStatuses
        .map((courseStatus) => (
          { label: courseStatus.description, value: courseStatus._links.courseStatus.href }));
      setCourseStatuses(result);
    };

    if (courseStatuses === undefined) {
      getCourseStatuses();
    }
  }, []);

  // update templates
  useEffect(() => {
    const getTemplates = async () => {
      const response = await getAllPagedForSelect('templates');
      const result = response._embedded?.templates
        .map((template) => ({
          label: template.shortDescription,
          value: template._links.template.href,
        }));
      setTemplates(result);
    };

    if (templates === undefined) {
      getTemplates();
    }
  }, []);

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  // set Tab Title
  useEffect(() => {
    document.title = 'Bildungsveranstaltung | Verwaltung';
  }, []);

  useEffect(() => {
    if (course._links !== undefined) {
      setSelfLink(course._links.self.href);
    }

    // sets formik values
    if (formikRef.current && course !== formikRef.current.values) {
      formikRef.current.setValues(course);
    }

    // sets template values for multi select
    // compares the templates of the course with the templates from the api
    // if the template is in the course, it will be added to the templateValues
    if (templates !== undefined && templates.length > 0 && course.templates !== undefined) {
      const values = templates.filter((template) => course.templates.includes(template.value));
      setTemplateValues(values);
    }
  }, [course]);

  return (
    <div className="container-fluid">
      <ButtonCard title="Bildungsveranstaltung" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Bildungsveranstaltung"
          setEntity={setCourse}
          defaultEntity={defaultCourse}
          settersToReset={[setTemplateValues, setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={course}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.COURSES,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={courseValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset,
                handleChange, values, setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} onReset={handleReset} noValidate>
                    <Modal.Body>
                      <Form.Group controlId="course-group">
                        <InputField
                          value={values.courseNumber}
                          handleChange={handleChange}
                          touched={touched.courseNumber}
                          errorDescription={errors.courseNumber}
                          fieldName="courseNumber"
                          placeholder="BV-Nr.*"
                          props={{ className: 'mb-2' }}
                        />
                        <SingleSelect
                          name="courseType"
                          placeholder="BV-Art*"
                        // pass object to defaultValue, so it be displayed in the select
                          value={courseTypes?.find((a) => a.value === values.courseType)}
                          values={courseTypes}
                          setFieldValue={setFieldValue}
                          touched={touched.courseType}
                          errorDescription={errors.courseType}
                        />
                        <InputField
                          value={values.startDate}
                          handleChange={handleChange}
                          type="date"
                          touched={touched.startDate}
                          errorDescription={errors.startDate}
                          fieldName="startDate"
                          placeholder="Start"
                          props={{ className: 'mb-2' }}
                        />
                        <InputField
                          value={values.endDate}
                          handleChange={handleChange}
                          type="date"
                          touched={touched.endDate}
                          errorDescription={errors.endDate}
                          fieldName="endDate"
                          placeholder="Ende"
                          props={{ className: 'mb-2' }}
                        />
                        <SingleSelect
                          name="courseStatus"
                          placeholder="Status"
                          // pass object to defaultValue, so it be displayed in the select
                          value={courseStatuses?.find((a) => a.value === values.courseStatus)}
                          values={courseStatuses}
                          setFieldValue={setFieldValue}
                          touched={touched.courseStatus}
                          errorDescription={errors.courseStatus}
                        />
                        <MultiSelect
                          name="templates"
                          placeholder="Templates"
                          options={templates}
                          values={templateValues}
                          setTemplateValues={setTemplateValues}
                          setFieldValue={setFieldValue}
                          touched={touched.templates}
                          errorDescription={errors.templates}
                        />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <ButtonGroup
                        handleDelete={() => handleDelete(selfLink, setUpdate)}
                        selfLink={selfLink !== undefined}
                        setShowDynModal={setShowDynModal}
                        setClassNameDynModal={setClassNameDynModal}
                      />
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
            )}
        />
        <PagedTable
          entityName={Bivema.COURSES}
          columns={columnsCourses}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={handleSetCourse}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default CourseOverview;
