import ErrorToastWithButton from '../components/toasts/ErrorToastWithButton';
import { logger } from '../wrapper/Logger';
import getFetchConfig, { HttpMethod, apiBaseUrl } from './Common';

/**
 * Use the /[entityName]?page=[]&size=[] endpoint of the REST API to get all entities paged.
 * @param {*} entityName - the name of the entity according to REST API
 * @param {*} page - which page to show
 * @param {*} pageSize - how many items per page
 * @returns JSON data from REST API
 */
const getAllPaged = async (entityName, page = 0, pageSize = 20) => {
  try {
    const url = `${apiBaseUrl + entityName}?page=${page}&size=${pageSize}`;

    const response = await fetch(url, getFetchConfig(HttpMethod.GET));

    const jsonData = await response.json();
    if (response.status === 404 || response.status === 409) {
      throw new Error(JSON.stringify(jsonData));
    }
    return jsonData;
  } catch (error) {
    logger.error(error);
    ErrorToastWithButton(error);
    return null;
  }
};
export default getAllPaged;
