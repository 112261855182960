export default function sortObjectArray(arr, fieldName) {
  if (arr === undefined || arr.length === 0) { return arr; }
  return arr.sort((a, b) => {
    if (a[fieldName] > b[fieldName]) {
      return 1;
    }
    if (a[fieldName] < b[fieldName]) {
      return -1;
    }
    return 0;
  });
}

export function getSelfLinkFromArrayObject(arr) {
  return arr?.map((value) => value._links.self.href);
}

export function getSelfLink(object) {
  return object?._links.self.href;
}
