import React from 'react';
import { toast } from 'react-toastify';
import './Toast.css';
import iconError from '../../assets/modo-icons/Icons/svg/coding_button_fail.svg';

export default function ErrorToast(text = 'Fehler') {
  toast.error(
    <div className="toast-message">
      {text}
    </div>,
    { icon: () => <img src={iconError} alt={text} className="svg-warning svg-icon" /> },
  );
}
