import React from 'react';
import {
  Table,
} from 'react-bootstrap';
import Renderer, { Renderers } from '../table/Renderer';

/**
 * Renders the BiVeMa report courseParticipantsCount.
 * @param {Object} courseParticipantCount - A list of CourseParticipantsCountDtos.
 */
const CourseParticipantsCount = ({ courseParticipantCount }) => {
  return (
    <Table responsive striped hover size="sm">
      <thead>
        <tr>
          <th key="startDate">Startdatum</th>
          <th key="courseNumber">Nummer</th>
          <th key="participantsCount">Anzahl Teilnehmende</th>
          <th key="participantsCountNotUmschulung">Ohne Umzuschulende</th>
          <th key="courseStatusDescription">Status</th>
        </tr>
      </thead>
      <tbody>
        {courseParticipantCount?.map((row) => (
          <tr>
            <td>
              <Renderer renderer={Renderers.Date} data={row.startDate} />
            </td>
            <td>
              {row.courseNumber}
            </td>
            <td>
              {row.participantsCount}
            </td>
            <td>
              {row.participantsCountNotUmschulung}
            </td>
            <td>
              {row.courseStatusDescription}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CourseParticipantsCount;
