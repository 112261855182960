// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-name {
    font-size: 1rem;
}

.user-mail {
    font-size: 0.7rem;
    font-weight:300;
}

.navbar .nav-link {
    font-size: 1.2rem !important;
}

.navbar .active {
    color: rgb(0, 24, 92) !important;
    font-weight: bold !important;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Navbar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,gCAAgC;IAChC,4BAA4B;AAChC","sourcesContent":[".user-name {\n    font-size: 1rem;\n}\n\n.user-mail {\n    font-size: 0.7rem;\n    font-weight:300;\n}\n\n.navbar .nav-link {\n    font-size: 1.2rem !important;\n}\n\n.navbar .active {\n    color: rgb(0, 24, 92) !important;\n    font-weight: bold !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
