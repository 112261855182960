import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import Validation from '../../utils/Validation';
import ValidationErrors from '../../utils/ValidationErrors';
import SingleSelect from '../../components/form/selects/SingleSelect';
import CheckBox from '../../components/form/Checkbox';
import MultiSelect from '../../components/form/selects/MultiSelect';
import gender from '../../utils/Gender';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';
import { Renderers } from '../../components/table/Renderer';
import getAllPagedForSelect from '../../api/GetAllPagedForSelect';
import { getSelfLinkFromArrayObject, getSelfLink } from '../../utils/Utils';

const columnsParticipants = [
  { title: 'Nachname', name: 'lastName' },
  { title: 'Vorname', name: 'firstName' },
  { title: 'E-Mail', name: 'email' },
  { title: 'Handynummer', name: 'mobile' },
  {
    title: 'Firma',
    name: 'name',
    relation: Bivema.COMPANY,
  },
  { title: 'Academy E-Mail', name: 'emailAcademy' },
  { title: 'Kunden Nr.', name: 'customerNumber' },
  {
    title: 'Kontaktpersonen',
    name: Bivema.CONTACT_PERSONS,
    relation: Bivema.CONTACT_PERSONS,
    renderer: Renderers.Hide,
  },
  {
    title: 'Qualifikation',
    name: Bivema.QUALIFICATION,
    relation: Bivema.QUALIFICATION,
    renderer: Renderers.Hide,
  },
  {
    title: 'Adresse',
    name: Bivema.ADDRESS,
    relation: Bivema.ADDRESS,
    renderer: Renderers.Hide,
  },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultParticipant = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  mobile: '',
  iban: '',
  bic: '',
  sepaMandate: '',
  newsletter: false,
  locked: false,
  documents: '',
  customerNumber: '',
  birthday: '',
  address: '',
  emailAcademy: '',
  adUser: false,
  birthplace: '',
  qualification: '',
  gender: null,
  company: '',
  customerNumberAtSponsor: '',
  contactPersons: [],
};
const participantValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable(true)
    .max(255, 'Darf maximal 255 Zeichen lang sein.'),
  lastName: Yup.string()
    .max(255, 'Darf maximal 255 Zeichen lang sein.')
    .required('Bitte geben Sie einen Nachnamen ein.'),
  email: Yup.string()
    .nullable(true)
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .matches(Validation.email, ValidationErrors.email),
  phone: Yup.string()
    .nullable(true)
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .matches(Validation.tel, ValidationErrors.tel),
  mobile: Yup.string()
    .nullable(true)
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .matches(Validation.tel, ValidationErrors.tel),
  iban: Yup.string()
    .nullable(true)
    .matches(Validation.iban, ValidationErrors.iban),
  bic: Yup.string()
    .nullable(true)
    .matches(Validation.bic, ValidationErrors.bic),
  sepaMandate: Yup.string()
    .nullable(true)
    .max(50, 'Darf maximal 50 Zeichen lang sein.'),
  newsletter: Yup.bool(),
  locked: Yup.bool(),
  documents: Yup.string()
    .nullable(true)
    .max(50, 'Darf maximal 50 Zeichen lang sein.'),
  customerNumber: Yup.number()
    .nullable(true)
    .typeError('Bitte nur Zahlen eingeben.')
    .min(40000, 'Die Kunden Nr. muss zwischen 40000 und 49999 liegen.')
    .max(49999, 'Die Kunden Nr. muss zwischen 40000 und 49999 liegen.'),
  birthday: Yup.date()
    .nullable(true),
  address: Yup.string()
    .nullable(true),
  emailAcademy: Yup.string()
    .nullable(true)
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .matches(Validation.email, ValidationErrors.email),
  adUser: Yup.bool(),
  birthplace: Yup.string()
    .nullable(true)
    .max(100, 'Darf maximal 100 Zeichen lang sein.'),
  qualification: Yup.string()
    .nullable(true),
  gender: Yup.string()
    .nullable(true),
  company: Yup.string()
    .nullable(true),
  customerNumberAtSponsor: Yup.string()
    .max(100, 'Darf maximal 100 Zeichen lang sein.')
    .nullable(true),
  contactPerson: Yup.array()
    .nullable(true),
});

const Participants = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [addresses, setAddresses] = useState();
  const [qualifications, setQualifications] = useState();
  const [companies, setCompanies] = useState();
  const [contactPersons, setContactPersons] = useState();
  const [contactPersonValues, setContactPersonValues] = useState([]);
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');
  const [participant, setParticipant] = useState({
    ...defaultParticipant,
  });

  const handleSetParticipant = (value) => {
    const editParticipant = {
      ...value,
      company: getSelfLink(value.company),
      address: getSelfLink(value.address),
      qualification: getSelfLink(value.qualification),
      contactPersons: getSelfLinkFromArrayObject(value.contactPersons),
    };

    setParticipant(editParticipant);
  };

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  // set Tab Title
  useEffect(() => {
    document.title = 'Teilnehmer | Verwaltung';
  }, []);

  // update addresses
  useEffect(() => {
    const getAddresses = async () => {
      const response = await getAllPagedForSelect('addresses');
      const result = response._embedded?.addresses
        .map((address) => ({ label: `${address.street} ${address.houseNumber}, ${address.city}`, value: address._links.address.href }));
      setAddresses(result);
    };

    if (addresses === undefined) {
      getAddresses();
    }
  }, [addresses]);

  // update qualifications
  useEffect(() => {
    const getQualifications = async () => {
      const response = await getAllPagedForSelect('qualifications');
      const result = response._embedded?.qualifications
        .map((qualification) => ({
          label: qualification.title,
          value: qualification._links.qualification.href,
        }));
      setQualifications(result);
    };

    if (qualifications === undefined) {
      getQualifications();
    }
  }, []);

  // update companies
  useEffect(() => {
    const getCompanies = async () => {
      const response = await getAllPagedForSelect('companies');
      const result = response._embedded?.companies
        .map((company) => ({
          label: company.name,
          value: company._links.company.href,
        }));
      setCompanies(result);
    };

    if (companies === undefined) {
      getCompanies();
    }
  }, []);

  // update contactPerson
  useEffect(() => {
    const getContactPersons = async () => {
      const response = await getAllPagedForSelect('contactPersons');
      const result = response._embedded?.contactPersons
        .map((contactPerson) => ({
          label: `${contactPerson.firstName} ${contactPerson.lastName}`,
          value: contactPerson._links.contactPerson.href,
        }));
      setContactPersons(result);
    };

    if (contactPersons === undefined) {
      getContactPersons();
    }
  }, []);

  useEffect(() => {
    if (participant._links !== undefined) {
      setSelfLink(participant._links.self.href);
    }

    // sets formik values
    if (formikRef.current && participant !== formikRef.current.values) {
      formikRef.current.setValues(participant);
    }

    // sets contactPerson values for multi select
    // compares the contactPerson of the participant with the contactPerson from the api
    // if the contactPerson is in the participant, it will be added to the contactPersonValues
    if (contactPersons !== undefined
      && contactPersons.length > 0
      && participant.contactPersons !== undefined) {
      const values = contactPersons.filter(
        (contactPerson) => participant.contactPersons.includes(contactPerson.value),
      );
      setContactPersonValues(values);
    }
  }, [participant]);

  return (
    <div className="container-fluid">
      <ButtonCard title="Teilnehmer" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Teilnehmer"
          setEntity={setParticipant}
          defaultEntity={defaultParticipant}
          settersToReset={[setContactPersonValues, setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalSize="xl"
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={participant}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.PARTICIPANTS,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={participantValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values, setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} onReset={handleReset} noValidate>
                    <Modal.Body>
                      <Form.Group controlId="participant-group">
                        <Container>
                          <Row>
                            <Col md={4}>
                              <InputField
                                value={values.firstName}
                                handleChange={handleChange}
                                touched={touched.firstName}
                                errorDescription={errors.firstName}
                                fieldName="firstName"
                                placeholder="Vorname"
                              />
                            </Col>
                            <Col md={4}>
                              <SingleSelect
                                name="gender"
                                placeholder="Geschlecht"
                                // pass object to defaultValue, so it be displayed in the select
                                value={gender.find((a) => a.value === values.gender)}
                                values={gender}
                                setFieldValue={setFieldValue}
                                touched={touched.gender}
                                errorDescription={errors.gender}
                              />
                            </Col>
                            <Col md={4}>
                              <SingleSelect
                                name="company"
                                placeholder="Firma"
                                // pass object to defaultValue, so it be displayed in the select
                                value={companies?.find((a) => a.value === values.company)}
                                values={companies}
                                setFieldValue={setFieldValue}
                                touched={touched.company}
                                errorDescription={errors.company}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <InputField
                                value={values.lastName}
                                handleChange={handleChange}
                                touched={touched.lastName}
                                errorDescription={errors.lastName}
                                fieldName="lastName"
                                placeholder="Nachname*"
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                            <Col md={4}>
                              <InputField
                                value={values.email}
                                handleChange={handleChange}
                                touched={touched.email}
                                errorDescription={errors.email}
                                fieldName="email"
                                placeholder="Email"
                              />
                            </Col>
                            <Col md={4}>
                              <MultiSelect
                                name="contactPersons"
                                placeholder="Ansprechpartner"
                                options={contactPersons}
                                values={contactPersonValues}
                                setTemplateValues={setContactPersonValues}
                                setFieldValue={setFieldValue}
                                touched={touched.contactPersons}
                                errorDescription={errors.contactPersons}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <SingleSelect
                                name="address"
                                placeholder="Adresse"
                                // pass object to defaultValue, so it be displayed in the select
                                value={addresses?.find((a) => a.value === values.address)}
                                values={addresses}
                                setFieldValue={setFieldValue}
                                touched={touched.address}
                                errorDescription={errors.address}
                              />
                            </Col>
                            <Col md={4}>
                              <InputField
                                value={values.emailAcademy}
                                handleChange={handleChange}
                                touched={touched.emailAcademy}
                                errorDescription={errors.emailAcademy}
                                fieldName="emailAcademy"
                                placeholder="Email Academy"
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                            <Col md={4}>
                              <InputField
                                value={values.documents}
                                handleChange={handleChange}
                                touched={touched.documents}
                                errorDescription={errors.documents}
                                fieldName="documents"
                                placeholder="Unterlagen"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <InputField
                                value={values.phone}
                                handleChange={handleChange}
                                touched={touched.phone}
                                errorDescription={errors.phone}
                                fieldName="phone"
                                placeholder="Telefon"
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                            <Col md={4}>
                              <InputField
                                value={values.iban}
                                handleChange={handleChange}
                                touched={touched.iban}
                                errorDescription={errors.iban}
                                fieldName="iban"
                                placeholder="IBAN"
                              />
                            </Col>
                            <Col md={4}>
                              <InputField
                                value={values.customerNumber}
                                handleChange={handleChange}
                                touched={touched.customerNumber}
                                errorDescription={errors.customerNumber}
                                fieldName="customerNumber"
                                placeholder="Kunden Nr."
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <InputField
                                value={values.mobile}
                                handleChange={handleChange}
                                touched={touched.mobile}
                                errorDescription={errors.mobile}
                                fieldName="mobile"
                                placeholder="Mobil"
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                            <Col md={4}>
                              <InputField
                                value={values.bic}
                                handleChange={handleChange}
                                touched={touched.bic}
                                errorDescription={errors.bic}
                                fieldName="bic"
                                placeholder="BIC"
                              />
                            </Col>
                            <Col md={4}>
                              <InputField
                                value={values.customerNumberAtSponsor}
                                handleChange={handleChange}
                                touched={touched.customerNumberAtSponsor}
                                errorDescription={errors.customerNumberAtSponsor}
                                fieldName="customerNumberAtSponsor"
                                placeholder="Kundenummer beim Kostenträger"
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <InputField
                                value={values.birthday}
                                handleChange={handleChange}
                                type="date"
                                touched={touched.birthday}
                                errorDescription={errors.birthday}
                                fieldName="birthday"
                                placeholder="Geburtstag"
                                props={{ className: 'mb-2' }}
                              />
                            </Col>
                            <Col md={4}>
                              <InputField
                                value={values.sepaMandate}
                                handleChange={handleChange}
                                touched={touched.sepaMandate}
                                errorDescription={errors.sepaMandate}
                                fieldName="sepaMandate"
                                placeholder="Sepa-Mandat"
                              />
                            </Col>
                            <Col md={4}>
                              <CheckBox
                                checked={values.adUser}
                                fieldname="adUser"
                                label="AD User"
                                handleChange={handleChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <InputField
                                value={values.birthplace}
                                handleChange={handleChange}
                                touched={touched.birthplace}
                                errorDescription={errors.birthplace}
                                fieldName="birthplace"
                                placeholder="Geburtsort"
                              />
                            </Col>
                            <Col md={4}>
                              <SingleSelect
                                name="qualification"
                                placeholder="Schulabschluss"
                                // pass object to defaultValue, so it be displayed in the select
                                value={qualifications?.find((a) => a.value
                                  === values.qualification)}
                                values={qualifications}
                                setFieldValue={setFieldValue}
                                touched={touched.qualification}
                                errorDescription={errors.qualification}
                              />
                            </Col>
                            <Col md={4}>
                              <CheckBox
                                checked={values.newsletter}
                                fieldname="newsletter"
                                label="Newsletter"
                                handleChange={handleChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4} />
                            <Col md={4} />
                            <Col md={4}>
                              <CheckBox
                                checked={values.locked}
                                fieldname="locked"
                                label="Gesperrt"
                                handleChange={handleChange}
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <ButtonGroup
                        handleDelete={() => handleDelete(selfLink, setUpdate)}
                        selfLink={selfLink !== undefined}
                        setShowDynModal={setShowDynModal}
                        setClassNameDynModal={setClassNameDynModal}
                      />
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
              )}
        />
        <PagedTable
          entityName={Bivema.PARTICIPANTS}
          columns={columnsParticipants}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={handleSetParticipant}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default Participants;
