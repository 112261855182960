import getAllPaged from '../../api/GetAllPaged';
import ErrorToast from '../toasts/ErrorToast';
import searchPaged from '../../api/SearchPaged';

/** Build paged table data after getting them from api/getAllPaged.
 * The relations of the entity are not resolved.
 * You can fetch the relation data with the _links attribute of the entity.
 * @param {string} entityName - Name of the entity to fetch
 * @param {number} page - Page number to fetch
 * @param {number} pageSize - Page size to fetch
 * @returns {object} Object with page and tableData
 * e.g.: { page: { number: 0, size: 20, totalElements: 1, totalPages: 1 }, tableData: [{...}] }
 */
export const buildTableData = async (entityName, page, pageSize) => {
  try {
    // get parent data from api
    const data = await getAllPaged(entityName, page, pageSize);

    // Nest tableData within additional page data
    const result = {
      page: data.page,
      tableData: data._embedded[entityName],
    };
    return result;
  } catch (error) {
    ErrorToast('Daten konnten nicht richtig geladen werden.');
    throw new Error(`Ein Fehler ist aufgetreten: ${error}`);
  }
};

/** Build paged table data with search after getting them from api/searchPaged.
 * The relations of the entity are not resolved.
 * You can fetch the relation data with the _links attribute of the entity.
 * @param {string} entityName - Name of the entity to fetch
 * @param {string} searchTerm - Search term to search for
 * @param {number} page - Page number to fetch
 * @param {number} pageSize - Page size to fetch
 * @returns {object} Object with page and tableData
 * e.g.: { page: { number: 0, size: 20, totalElements: 1, totalPages: 1 }, tableData: [{...}] }
 */
export const buildTableDataSearch = async (entityName, searchTerm, page, pageSize) => {
  try {
    // get parent data from api
    const data = await searchPaged(entityName, searchTerm, page, pageSize);

    // Nest tableData within additional page data
    const result = {
      page: data.page,
      tableData: data._embedded[entityName],
    };
    return result;
  } catch (error) {
    ErrorToast('Daten konnten nicht richtig geladen werden.');
    throw new Error(`Ein Fehler ist aufgetreten: ${error}`);
  }
};
