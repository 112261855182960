import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';

const columnsCourseTypes = [
  { title: 'Beschreibung', name: 'description' },
  { title: 'Erläuterung', name: 'explanation' },
  { title: 'Kurzbeschreibung', name: 'shortDescription' },
  { title: 'Edit', name: '_links.self.href' },
];

const relations = [];

const defaultCourseType = {
  description: '', explanation: '', shortDescription: '',
};

const courseTypeValidationSchema = Yup.object().shape({
  description: Yup.string()
    .max(255, 'Darf maximal 255 Zeichen lang sein.')
    .required('Bitte geben Sie eine Beschreibung an.'),
  explanation: Yup.string()
    .nullable(true)
    .max(255, 'Darf maximal 255 Zeichen lang sein.'),
  shortDescription: Yup.string()
    .nullable(true)
    .max(50, 'Darf maximal 50 Zeichen lang sein.'),
});

const CourseTypesOverview = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');

  const [courseType, setCourseType] = useState({
    ...defaultCourseType,
  });

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  // set Tab Title
  useEffect(() => {
    document.title = 'BV-Art | Stammdaten';
  }, []);

  useEffect(() => {
    if (courseType._links !== undefined) {
      setSelfLink(courseType._links.self.href);
    }

    // sets formik values
    if (formikRef.current && courseType !== formikRef.current.values) {
      formikRef.current.setValues(courseType);
    }
  }, [courseType]);
  return (
    <div className="container-fluid">
      <ButtonCard title="Bildungsveranstaltung-Art" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Biildungsveranstaltung-Art"
          setEntity={setCourseType}
          defaultEntity={defaultCourseType}
          settersToReset={[setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={courseType}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.COURSE_TYPES,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={courseTypeValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values,
              }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                  <Modal.Body>
                    <Form.Group controlId="description-group">
                      <InputField
                        value={values.description}
                        handleChange={handleChange}
                        touched={touched.description}
                        errorDescription={errors.description}
                        fieldName="description"
                        placeholder="Beschreibung*"
                        props={{ className: 'mb-2' }}
                      />
                      <InputField
                        value={values.shortDescription}
                        handleChange={handleChange}
                        touched={touched.shortDescription}
                        errorDescription={errors.shortDescription}
                        fieldName="shortDescription"
                        placeholder="Kurzbeschreibung"
                        props={{ className: 'mb-2' }}
                      />
                      <InputField
                        value={values.explanation}
                        handleChange={handleChange}
                        touched={touched.explanation}
                        errorDescription={errors.explanation}
                        fieldName="explanation"
                        placeholder="Erläuterung"
                        props={{ className: 'mb-2' }}
                      />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonGroup
                      handleDelete={() => handleDelete(selfLink, setUpdate)}
                      selfLink={selfLink !== undefined}
                      setShowDynModal={setShowDynModal}
                      setClassNameDynModal={setClassNameDynModal}
                    />
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          )}
        />
        <PagedTable
          entityName={Bivema.COURSE_TYPES}
          entityRelations={relations}
          columns={columnsCourseTypes}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={setCourseType}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default CourseTypesOverview;
