import Select from 'react-select';
import { borderColor, selectMenuToFront, placeholderColor } from './SelectStyle';

/**
 * A single select wich is search and clearable.
 *
 * @param {*} name Name of the select. Use the object field name of the entity.
 * @param {*} placeholder Placeholder of the field
 * @param {*} options List of options for the select. Each list item is an object,
 * consisting of value and label
 * @param {*} setFieldValue Formik setFieldValue function
 * @param {*} value value of the field.
 * Value is an object consisting of value and label,
 * where label is displayed in select and value is used for post.
 * @param {*} touched Boolean if the field was touched
 * @param {*} errorDescription Error description of the field, when invalid
 * @param {*} width width of the control, default '100%'
 * @param {*} height height of the control, default 58
 *
 * @return {*}
 */
const SingleSelect = ({
  name,
  placeholder,
  values: options,
  setFieldValue,
  value,
  touched,
  errorDescription,
  width = '100%',
  height = 58, // height of the select box
}) => {
  const customStlye = {
    control: (provided, state) => ({
      ...provided,
      ...borderColor(state, errorDescription, touched),
      height,
      width,
    }),
  };
  return (
    <div className="mb-2">
      <Select
        isSearchable
        placeholder={placeholder}
        onChange={(e) => {
        // if e is null, then the user has cleared the select
          if (e === null) {
            setFieldValue(name, '');
          } else {
            setFieldValue(name, e.value);
          }
        }}
        name={name}
        options={options}
        // value needs to be an object, otherwise the select will not display the value
        value={value || ''} // '' resets the select after new entity is created
        className={errorDescription === undefined ? 'basic-single-select' : 'basic-single-select is-invalid'}
        classNamePrefix="select"
        styles={Object.assign(
          selectMenuToFront,
          placeholderColor,
          customStlye,
        )}
        isClearable
        menuPosition="fixed"
        isLoading={options === undefined}
      />
      <div className="invalid-feedback">{errorDescription}</div>
    </div>
  );
};

export default SingleSelect;
