import React from 'react';
import Card from './Card';
import CreateButton from '../CreateButton';

const ButtonCard = ({
  children, title, setShowDynModal,
}) => {
  return (
    <Card>
      <div className="row justify-content-between mb-3">
        <div className="col">
          <h3 className="card-title realtime-rounded">
            {title}
          </h3>
        </div>
        <div className="col text-end">
          <CreateButton title="anlegen" setShowDynModal={setShowDynModal} />
        </div>
      </div>
      <div className="card-text">
        {children}
      </div>
    </Card>
  );
};

export default ButtonCard;
