import React from 'react';
import { Card } from 'react-bootstrap';

const TitleSubtitleCard = ({ children, title, subtitle }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title className="realtime-rounded">
          {title}
        </Card.Title>
        <Card.Subtitle>
          {subtitle}
        </Card.Subtitle>
        <Card.Text className="pt-2">
          {children}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default TitleSubtitleCard;
