import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';

const columnsSponsor = [
  { title: 'Bezeichnung', name: 'description' },
  { title: 'Kunden Nr.', name: 'customerNumber' },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultSponsor = {
  description: '', customerNumber: '',
};

const sponsorValidationSchema = Yup.object().shape({
  description: Yup.string()
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .required('Bitte geben Sie eine Bezeichnung ein.'),
  customerNumber: Yup.number()
    .nullable(true)
    .typeError('Bitte nur Zahlen eingeben.')
    .min(20000, 'Die Kunden Nr. muss zwischen 20000 und 29999 liegen.')
    .max(29999, 'Die Kunden Nr. muss zwischen 20000 und 29999 liegen.'),
});

const SponsorOverview = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');

  const [sponsor, setSponsor] = useState({
    ...defaultSponsor,
  });

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  // set Tab Title
  useEffect(() => {
    document.title = 'Kostenträger | Stammdaten';
  }, []);

  useEffect(() => {
    if (sponsor._links !== undefined) {
      setSelfLink(sponsor._links.self.href);
    }

    // sets formik values
    if (formikRef.current && sponsor !== formikRef.current.values) {
      formikRef.current.setValues(sponsor);
    }
  }, [sponsor]);
  return (
    <div className="container-fluid">
      <ButtonCard title="Kostenträger" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Kostenträger"
          setEntity={setSponsor}
          defaultEntity={defaultSponsor}
          settersToReset={[setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={sponsor}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.SPONSORS,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={sponsorValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values,
              }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                  <Modal.Body>
                    <Form.Group controlId="sponsor-group">
                      <InputField
                        value={values.description}
                        handleChange={handleChange}
                        touched={touched.description}
                        errorDescription={errors.description}
                        fieldName="description"
                        placeholder="Bezeichnung*"
                        props={{ className: 'mb-2' }}
                      />
                      <InputField
                        value={values.customerNumber}
                        handleChange={handleChange}
                        touched={touched.customerNumber}
                        errorDescription={errors.customerNumber}
                        fieldName="customerNumber"
                        placeholder="Kunden Nr."
                      />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonGroup
                      handleDelete={() => handleDelete(selfLink, setUpdate)}
                      selfLink={selfLink !== undefined}
                      setShowDynModal={setShowDynModal}
                      setClassNameDynModal={setClassNameDynModal}
                    />
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          )}
        />

        <PagedTable
          entityName={Bivema.SPONSORS}
          columns={columnsSponsor}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={setSponsor}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default SponsorOverview;
