import KeycloakUtil from '../utils/KeycloakUtil/KeycloakUtil';

/** Supported HTTP methods of the getFetchConfig Method */
export const HttpMethod = {
  GET: 'GET',
  POST: 'POST',
};

/** Base URL of the API set via NODE env variable */
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

/** getFetchConfig prepares the configuration for fetch requests including
 * the authorization header.
 */
const getFetchConfig = ({ httpMethod }) => {
  const authorization = `Bearer ${KeycloakUtil.getToken()}`;

  return {
    method: httpMethod,
    mode: 'cors',
    headers: {
      Authorization: authorization,
    },
  };
};

export default getFetchConfig;
