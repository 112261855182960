import ErrorToastWithButton from '../components/toasts/ErrorToastWithButton';
import { logger } from '../wrapper/Logger';
import getFetchConfig, { HttpMethod, apiBaseUrl } from './Common';

/**
 * Use the /[entityName] endpoint of the REST API to get all entities with a page size of 999999.
 * This is only used for selects to show ALL entries of an entity,
 * since the paged API would otherwise only show the default of 20 entries.
 * @param {*} entityName - the name of the entity according to REST API
 * @returns JSON data from REST API
 */
const getAllPagedForSelect = async (entityName) => {
  try {
    const url = `${apiBaseUrl + entityName}?page=0&size=9999999`;

    const response = await fetch(url, getFetchConfig(HttpMethod.GET));

    const jsonData = await response.json();
    if (response.status === 404 || response.status === 409) {
      throw new Error(JSON.stringify(jsonData));
    }
    return jsonData;
  } catch (error) {
    logger.error(error);
    ErrorToastWithButton(error);
    return null;
  }
};
export default getAllPagedForSelect;
