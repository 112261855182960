import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import Validation from '../../utils/Validation';
import ValidationErrors from '../../utils/ValidationErrors';
import SingleSelect from '../../components/form/selects/SingleSelect';
import CheckBox from '../../components/form/Checkbox';
import gender from '../../utils/Gender';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';
import { Renderers } from '../../components/table/Renderer';
import getAllPagedForSelect from '../../api/GetAllPagedForSelect';
import { getSelfLink } from '../../utils/Utils';

const columnsContactPersons = [
  { title: 'Nachname', name: 'lastName' },
  { title: 'Vorname', name: 'firstName' },
  { title: 'Email', name: 'email' },
  { title: 'Telefon', name: 'phone' },
  { title: 'Firma', name: 'name', relation: Bivema.COMPANY },
  { title: 'Abt./Position', name: 'department' },
  { title: 'Newsletter', name: 'newsletter', renderer: Renderers.Boolean },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultContactPerson = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  company: '',
  department: '',
  newsletter: false,
  gender: null,
};

const contactPersonValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable(true)
    .max(255, 'Darf maximal 255 Zeichen lang sein.'),
  lastName: Yup.string()
    .max(255, 'Darf maximal 255 Zeichen lang sein.')
    .required('Bitte geben Sie einen Nachnamen ein.'),
  email: Yup.string()
    .nullable(true)
    .max(255, 'Darf maximal 255 Zeichen lang sein.')
    .matches(Validation.email, ValidationErrors.email),
  phone: Yup.string()
    .nullable(true)
    .max(100, 'Darf maximal 100 Zeichen lang sein.')
    .matches(Validation.tel, ValidationErrors.tel),
  company: Yup.string()
    .required('Bitte eine Firma auswählen.'),
  department: Yup.string()
    .nullable(true)
    .max(255, 'Darf maximal 255 Zeichen lang sein.'),
  newsletter: Yup.bool(),
  gender: Yup.string()
    .nullable(true),
});

const ContactPersonOverview = () => {
  const [companies, setCompanies] = useState();
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');
  const [contactPerson, setContactPerson] = useState({
    ...defaultContactPerson,
  });

  const handleSetContactPerson = (value) => {
    const editContactPerson = {
      ...value,
      company: getSelfLink(value.company),
    };

    setContactPerson(editContactPerson);
  };

  // set Tab Title
  useEffect(() => {
    document.title = 'Ansprechpartner | Verwaltung';
  }, []);

  // update companies
  useEffect(() => {
    const getCompanies = async () => {
      const response = await getAllPagedForSelect('companies');
      const result = response._embedded?.companies
        .map((company) => ({ label: company.name, value: company._links.company.href }));
      setCompanies(result);
    };

    if (companies === undefined) {
      getCompanies();
    }
  }, []);

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  useEffect(() => {
    if (contactPerson._links !== undefined) {
      setSelfLink(contactPerson._links.self.href);
    }

    // sets formik values
    if (formikRef.current && contactPerson !== formikRef.current.values) {
      formikRef.current.setValues(contactPerson);
    }
  }, [contactPerson]);

  return (
    <div className="container-fluid">
      <ButtonCard title="Ansprechpartner" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Ansprechpartner"
          setEntity={setContactPerson}
          defaultEntity={defaultContactPerson}
          settersToReset={[setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={contactPerson}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.CONTACT_PERSONS,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={contactPersonValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values, setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} onReset={handleReset} noValidate>
                    <Modal.Body>
                      <Form.Group controlId="contactPerson-group">
                        <SingleSelect
                          name="gender"
                          placeholder="Geschlecht"
                          // pass object to defaultValue, so it be displayed in the select
                          value={gender.find((a) => a.value === values.gender)}
                          values={gender}
                          setFieldValue={setFieldValue}
                          touched={touched.gender}
                          errorDescription={errors.gender}
                        />
                        <InputField
                          value={values.firstName}
                          handleChange={handleChange}
                          touched={touched.firstName}
                          errorDescription={errors.firstName}
                          fieldName="firstName"
                          placeholder="Vorname"
                          props={{ className: 'mb-2' }}
                        />
                        <InputField
                          value={values.lastName}
                          handleChange={handleChange}
                          touched={touched.lastName}
                          errorDescription={errors.lastName}
                          fieldName="lastName"
                          placeholder="Nachname*"
                          props={{ className: 'mb-2' }}
                        />
                        <InputField
                          value={values.email}
                          handleChange={handleChange}
                          touched={touched.email}
                          errorDescription={errors.email}
                          fieldName="email"
                          placeholder="Email"
                          props={{ className: 'mb-2' }}
                        />
                        <InputField
                          value={values.phone}
                          handleChange={handleChange}
                          touched={touched.phone}
                          errorDescription={errors.phone}
                          fieldName="phone"
                          placeholder="Telefonnummer"
                          props={{ className: 'mb-2' }}
                        />
                        <SingleSelect
                          name="company"
                          placeholder="Firma*"
                          // pass object to defaultValue, so it be displayed in the select
                          value={companies?.find((a) => a.value === values.company)}
                          values={companies}
                          setFieldValue={setFieldValue}
                          touched={touched.company}
                          errorDescription={errors.company}
                        />
                        <InputField
                          value={values.department}
                          handleChange={handleChange}
                          touched={touched.title}
                          errorDescription={errors.department}
                          fieldName="department"
                          placeholder="Position/Abteilung"
                          props={{ className: 'mb-2' }}
                        />
                        <CheckBox
                          checked={values.newsletter}
                          fieldname="newsletter"
                          label="Newsletter"
                          handleChange={handleChange}
                        />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <ButtonGroup
                        handleDelete={() => handleDelete(selfLink, setUpdate)}
                        selfLink={selfLink !== undefined}
                        setShowDynModal={setShowDynModal}
                        setClassNameDynModal={setClassNameDynModal}
                      />
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
              )}
        />
        <PagedTable
          entityName={Bivema.CONTACT_PERSONS}
          columns={columnsContactPersons}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={handleSetContactPerson}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default ContactPersonOverview;
