// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    background-color: rgb(237,230,220);
    margin-top: 75px;
    padding-top:1rem;
    padding-bottom: 1rem;
    width: 250px;
    height: calc(100vh - 78px);
    position: fixed;
    overflow: auto;
}

.sidebar .nav-link {
    font-size: 1.1rem !important;
    font-weight: 300 !important;
    margin: 0.2rem 0.5rem !important;
    transition: 0.3s;
    border-radius: 0.5rem;
    color: black;
}

.sidebar .nav-link:hover {
    color: rgb(39,50,36);
    background-color: rgba(255, 255, 255, 0.8);
    transition: 0.3s;
}

.sidebar .nav-link.active {
    color: rgb(39,50,36);
    background-color: rgba(255, 255, 255, 0.8);
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;IACpB,YAAY;IACZ,0BAA0B;IAC1B,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,4BAA4B;IAC5B,2BAA2B;IAC3B,gCAAgC;IAChC,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,0CAA0C;IAC1C,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,0CAA0C;AAC9C","sourcesContent":[".sidebar {\n    background-color: rgb(237,230,220);\n    margin-top: 75px;\n    padding-top:1rem;\n    padding-bottom: 1rem;\n    width: 250px;\n    height: calc(100vh - 78px);\n    position: fixed;\n    overflow: auto;\n}\n\n.sidebar .nav-link {\n    font-size: 1.1rem !important;\n    font-weight: 300 !important;\n    margin: 0.2rem 0.5rem !important;\n    transition: 0.3s;\n    border-radius: 0.5rem;\n    color: black;\n}\n\n.sidebar .nav-link:hover {\n    color: rgb(39,50,36);\n    background-color: rgba(255, 255, 255, 0.8);\n    transition: 0.3s;\n}\n\n.sidebar .nav-link.active {\n    color: rgb(39,50,36);\n    background-color: rgba(255, 255, 255, 0.8);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
