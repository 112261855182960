import React from 'react';
import CodingButtonAdd from '../assets/modo-icons/Icons/svg/coding_button_add.svg';

const CreateButton = ({ title, setShowDynModal }) => {
  const handleOnClick = () => {
    setShowDynModal(true);
  };
  return (
    <div>
      <button type="button" className="btn btn-dark bg-panther border-0 text-white text-uppercase" onClick={handleOnClick}>
        <img
          height="20"
          src={CodingButtonAdd}
          alt="logo"
          className="me-2 svg-white"
        />
        {title}
      </button>
    </div>
  );
};
export default CreateButton;
