import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';

const columnsCourseStauses = [
  { title: 'Beschreibung', name: 'description' },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultCourseStatus = {
  description: '',
};

const courseStatusValidationSchema = Yup.object().shape({
  description: Yup.string()
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .required('Bitte geben Sie eine Beschreibung ein.'),
});

const CourseStatusOverview = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');

  const [courseStatus, setCourseStauses] = useState({
    ...defaultCourseStatus,
  });

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  // set Tab Title
  useEffect(() => {
    document.title = 'BV-Status | Stammdaten';
  }, []);

  useEffect(() => {
    if (courseStatus._links !== undefined) {
      setSelfLink(courseStatus._links.self.href);
    }

    // sets formik values
    if (formikRef.current && courseStatus !== formikRef.current.values) {
      formikRef.current.setValues(courseStatus);
    }
  }, [courseStatus]);
  return (
    <div className="container-fluid">
      <ButtonCard title="BV-Status" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="BV-Status"
          setEntity={setCourseStauses}
          defaultEntity={defaultCourseStatus}
          settersToReset={[setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalBody={(
            <Formik
              innerRef={formikRef}
              initialValues={courseStatus}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.COURSE_STATUSES,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={courseStatusValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values,
              }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                  <Modal.Body>
                    <Form.Group controlId="course-status-group">
                      <InputField
                        value={values.description}
                        handleChange={handleChange}
                        touched={touched.description}
                        errorDescription={errors.description}
                        fieldName="description"
                        placeholder="Beschreibung*"
                      />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonGroup
                      handleDelete={() => handleDelete(selfLink, setUpdate)}
                      selfLink={selfLink !== undefined}
                      setShowDynModal={setShowDynModal}
                      setClassNameDynModal={setClassNameDynModal}
                    />
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          )}
        />
        <PagedTable
          entityName={Bivema.COURSE_STATUSES}
          columns={columnsCourseStauses}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={setCourseStauses}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default CourseStatusOverview;
