import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import SingleSelect from '../../components/form/selects/SingleSelect';
import MultiSelect from '../../components/form/selects/MultiSelect';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';
import { Renderers } from '../../components/table/Renderer';
import getAllPagedForSelect from '../../api/GetAllPagedForSelect';
import { getSelfLinkFromArrayObject, getSelfLink } from '../../utils/Utils';

const columnsTimetableEntries = [
  {
    title: 'BV',
    name: Bivema.COURSES,
    relation: Bivema.COURSES,
    renderer: Renderers.Courses,
  },
  {
    title: 'Fach',
    name: 'abbreviation',
    relation: Bivema.SUBJECT,
  },
  {
    title: 'Dozent',
    name: Bivema.LECTURER,
    relation: Bivema.LECTURER,
    renderer: Renderers.FullName,
  },
  {
    title: 'Raum',
    name: Bivema.ROOMS,
    relation: Bivema.ROOMS,
    renderer: Renderers.Rooms,
  },
  { title: 'Beginn', name: 'start', renderer: Renderers.DateTime },
  { title: 'Ende', name: 'end', renderer: Renderers.DateTime },
  { title: 'Unterrichtseinheiten', name: 'units' },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultTimetableEntry = {
  courses: [],
  subject: '',
  lecturer: '',
  rooms: [],
  start: '',
  end: '',
  units: '',
};
const timetableEntryValidationSchema = Yup.object().shape({
  courses: Yup.array()
    .min(1, 'Bitte eine Bildungsveranstaltung auswählen.')
    .required('Bitte eine Bildungsveranstaltung auswählen.'),
  subject: Yup.string()
    .nullable(true),
  lecturer: Yup.string()
    .required('Bitte einen Dozenten auswählen.'),
  rooms: Yup.array()
    .nullable(true),
  start: Yup.date()
    .required('Bitte ein Startdatum auswählen.'),
  end: Yup.date().min(
    Yup.ref('start'),
    'Das Enddatum darf nicht vor dem Startdatum liegen',
  )
    .required('Bitte ein Enddatum auswählen.'),

  units: Yup.number()
    .typeError('Bitte geben Sie eine Zahl ein.')
    .nullable(true),
});

const TimtableEntriesOverview = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [lecturers, setLecturers] = useState();
  const [rooms, setRooms] = useState();
  const [roomsValues, setRoomsValues] = useState([]);
  const [courses, setCourses] = useState();
  const [coursesValues, setCoursesValues] = useState([]);
  const [subjects, setSubjects] = useState();
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');

  const [timetableEntry, setTimetableEntry] = useState({
    ...defaultTimetableEntry,
  });

  const handleSetTimetable = (value) => {
    const editTimetable = {
      ...value,
      lecturer: getSelfLink(value.lecturer),
      rooms: getSelfLinkFromArrayObject(value.rooms),
      courses: getSelfLinkFromArrayObject(value.courses),
      subject: getSelfLink(value.subject),
    };

    setTimetableEntry(editTimetable);
  };

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  // set Tab Title
  useEffect(() => {
    document.title = 'Stundenplan | Verwaltung';
  }, []);

  // update Lecturers
  useEffect(() => {
    const getLecturers = async () => {
      const response = await getAllPagedForSelect('lecturers');
      const result = response._embedded?.lecturers
        .map((lecturer) => ({
          label: `${lecturer.firstName} ${lecturer.lastName}`,
          value: lecturer._links.lecturer.href,
        }));
      setLecturers(result);
    };

    if (lecturers === undefined) {
      getLecturers();
    }
  }, []);

  // update Rooms
  useEffect(() => {
    const getRooms = async () => {
      const response = await getAllPagedForSelect('rooms');
      const result = response._embedded?.rooms
        .map((room) => (
          {
            label: room.number,
            value: room._links.room.href,
          }));
      setRooms(result);
    };

    if (rooms === undefined) {
      getRooms();
    }
  }, []);

  // update course
  useEffect(() => {
    const getCourses = async () => {
      const response = await getAllPagedForSelect('courses');
      const result = response._embedded?.courses
        .map((course) => ({
          label: course.courseNumber,
          value: course._links.course.href,
        }));
      setCourses(result);
    };

    if (courses === undefined) {
      getCourses();
    }
  }, []);

  // update Subjects
  useEffect(() => {
    const getSubjects = async () => {
      const response = await getAllPagedForSelect('subjects');
      const result = response._embedded?.subjects
        .map((subject) => ({
          label: subject.abbreviation,
          value: subject._links.subject.href,
        }));
      setSubjects(result);
    };

    if (subjects === undefined) {
      getSubjects();
    }
  }, []);

  useEffect(() => {
    if (timetableEntry._links !== undefined) {
      setSelfLink(timetableEntry._links.self.href);
    }

    // sets formik values
    if (formikRef.current && timetableEntry !== formikRef.current.values) {
      formikRef.current.setValues(timetableEntry);
    }

    // sets course values for multi select
    // compares the course of the timetableEntry with the timetableEntry from the api
    // if the course is in the timetableEntry, it will be added to the coursesValues
    if (courses !== undefined && courses.length > 0 && timetableEntry.courses !== undefined) {
      const values = courses.filter((course) => timetableEntry.courses.includes(course.value));
      setCoursesValues(values);
    }
    // sets room values for multi select
    // compares the room of the timetableEntry with the room from the api
    // if the room is in the timetableEntry, it will be added to the timetableEntryValues
    if (rooms !== undefined && rooms.length > 0 && timetableEntry.rooms !== undefined) {
      const values = rooms.filter((room) => timetableEntry.rooms.includes(room.value));
      setRoomsValues(values);
    }
  }, [timetableEntry]);

  return (
    <div className="container-fluid">
      <ButtonCard title="Stundenplan" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Veranstaltungszeit"
          setEntity={setTimetableEntry}
          defaultEntity={defaultTimetableEntry}
          settersToReset={[setSelfLink, setCoursesValues, setRoomsValues]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={timetableEntry}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.TIMETABLE_ENTRIES,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={timetableEntryValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values, setFieldValue,
              }) => {
                // useEffect to update 'end' with a preset time
                useEffect(() => {
                  if (values.start) {
                    const datePart = values.start.slice(0, 10); // 'YYYY-MM-DD'
                    const formattedEndDate = `${datePart}T15:15`;
                    setFieldValue('end', formattedEndDate);
                  }
                }, [values.start, setFieldValue]);

                return (
                  <Form onSubmit={handleSubmit} onReset={handleReset} noValidate>
                    <Modal.Body>
                      <Form.Group controlId="timetableEntry-group">
                        <MultiSelect
                          name="courses"
                          placeholder="Bildungsveranstaltung*"
                          options={courses}
                          values={coursesValues}
                          setTemplateValues={setCoursesValues}
                          setFieldValue={setFieldValue}
                          touched={touched.courses}
                          errorDescription={errors.courses}
                          closeMenuOnSelect={false}
                        />
                        <SingleSelect
                          name="subject"
                          placeholder="Fach"
                          // pass object to defaultValue, so it be displayed in the select
                          value={subjects?.find((a) => a.value === values.subject)}
                          values={subjects}
                          setFieldValue={setFieldValue}
                          touched={touched.subject}
                          errorDescription={errors.subject}
                        />
                        <SingleSelect
                          name="lecturer"
                          placeholder="Dozent*"
                          // pass object to defaultValue, so it be displayed in the select
                          value={lecturers?.find((a) => a.value === values.lecturer)}
                          values={lecturers}
                          setFieldValue={setFieldValue}
                          touched={touched.lecturer}
                          errorDescription={errors.lecturer}
                        />
                        <MultiSelect
                          name="rooms"
                          placeholder="Raum"
                          options={rooms}
                          values={roomsValues}
                          setTemplateValues={setRoomsValues}
                          setFieldValue={setFieldValue}
                          touched={touched.rooms}
                          errorDescription={errors.rooms}
                        />
                        <InputField
                          value={values.start}
                          handleChange={handleChange}
                          type="datetime-local"
                          touched={touched.start}
                          errorDescription={errors.start}
                          fieldName="start"
                          placeholder="Beginn*"
                          props={{ className: 'mb-2' }}
                        />
                        <InputField
                          value={values.end}
                          handleChange={handleChange}
                          type="datetime-local"
                          touched={touched.end}
                          errorDescription={errors.end}
                          fieldName="end"
                          placeholder="Ende*"
                          props={{ className: 'mb-2' }}
                        />
                        <InputField
                          value={values.units}
                          handleChange={handleChange}
                          touched={touched.units}
                          errorDescription={errors.units}
                          fieldName="units"
                          placeholder="Unterrichtseinheiten"
                          defaultValue={8}
                        />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <ButtonGroup
                        handleDelete={() => handleDelete(selfLink, setUpdate)}
                        selfLink={selfLink !== undefined}
                        setShowDynModal={setShowDynModal}
                        setClassNameDynModal={setClassNameDynModal}
                      />
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
              )}
        />
        <PagedTable
          entityName={Bivema.TIMETABLE_ENTRIES}
          columns={columnsTimetableEntries}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={handleSetTimetable}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default TimtableEntriesOverview;
