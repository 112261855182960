import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logoMischok from '../../assets/logo/RGB/PNG/LOGO_L_POS_RGB.png';
import logoMischokSmall from '../../assets/logo/RGB/PNG/LOGO_P_POS_RGB.png';
import iconLogout from '../../assets/modo-icons/Icons/png64/multimedia_button_turn_on_64.png';
import iconUser from '../../assets/modo-icons/Icons/png64/user_male_circle_64.png';
import KeycloakUtil from '../../utils/KeycloakUtil/KeycloakUtil';
import './Navbar.css';

export default function Navbar() {
  const [user, setUser] = useState();

  useEffect(() => {
    setUser(KeycloakUtil?.getUserData());
  }, []);

  return (
    <nav className="navbar fixed-top navbar-expand-sm shadow bg-white">
      <div className="container-fluid">
        <div className="navbar-brand">
          <Link to="/">
            <div className="d-none d-lg-block" style={{ width: '250px' }}>
              <img
                height="50"
                className="img-responsive"
                src={logoMischok}
                alt="logo"
              />
            </div>
            <img
              height="50"
              className="img-responsive d-lg-none"
              src={logoMischokSmall}
              alt="logo"
            />
          </Link>
        </div>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav realtime-rounded">
            <NavLink
              to="/management"
              className={({ isActive }) => (isActive
                ? 'nav-link active'
                : 'nav-link')}
            >
              Verwaltung
            </NavLink>
            <NavLink
              to="/sponsors"
              className={({ isActive }) => (isActive
                ? 'nav-link active'
                : 'nav-link')}
            >
              Basisdaten
            </NavLink>
            <NavLink
              to="/reports"
              className={({ isActive }) => (isActive
                ? 'nav-link active'
                : 'nav-link')}
            >
              Reports
            </NavLink>
          </div>
        </div>

        <div className="row d-none d-lg-flex m-0">
          <div className="col d-flex flex-column p-0 realtime-rounded">
            <span className="user-name text-end fw-bold">{user?.name}</span>
            <span className="user-mail text-end text-panther">{user?.email}</span>
          </div>
          <div className="col d-flex align-items-center">
            <img src={iconUser} alt="User Icon" height={35} className="svg-midnight" />
            <button type="button" className="bg-transparent border-0" onClick={() => KeycloakUtil.doLogout()}>
              <img src={iconLogout} alt="Logout Icon" height={35} className="svg-warning ms-2" />
            </button>
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
      </div>
    </nav>
  );
}
