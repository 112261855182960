import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import MultiSelect from '../../components/form/selects/MultiSelect';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';
import { Renderers } from '../../components/table/Renderer';
import getAllPagedForSelect from '../../api/GetAllPagedForSelect';
import { getSelfLinkFromArrayObject } from '../../utils/Utils';

const columnsCompany = [
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Adresse',
    name: Bivema.ADDRESSES,
    relation: Bivema.ADDRESSES,
    renderer: Renderers.Addresses,
  },
  {
    title: 'Ansprechpartner',
    name: Bivema.CONTACT_PERSONS,
    relation: Bivema.CONTACT_PERSONS,
    renderer: Renderers.ContactPersons,
  },
  {
    title: 'Rechnungsempfänger',
    name: Bivema.INVOICE_RECIPIENTS,
    relation: Bivema.INVOICE_RECIPIENTS,
    renderer: Renderers.InvoiceRecipients,
  },
  {
    title: 'Kunden Nr.',
    name: 'customerNumber',
  },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultCompany = {
  name: '', addresses: [], customerNumber: '',
};

const companyValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, 'Darf maximal 255 Zeichen lang sein.')
    .required('Bitte geben Sie einen Namen ein.'),
  addresses: Yup.array()
    .nullable(true),
  customerNumber: Yup.number()
    .nullable(true)
    .typeError('Bitte nur Zahlen eingeben.')
    .min(30000, 'Die Kunden Nr. muss zwischen 30000 und 39999 liegen.')
    .max(39999, 'Die Kunden Nr. muss zwischen 30000 und 39999 liegen.'),
});

const CompanyOverview = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [addresses, setAddresses] = useState();
  const [addressValues, setAddressValues] = useState([]);
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');

  const [company, setCompany] = useState({
    ...defaultCompany,
  });

  const handleSetCompany = (value) => {
    const editCompany = {
      _links: value._links,
      name: value.name,
      addresses: getSelfLinkFromArrayObject(value.addresses),
      customerNumber: value.customerNumber,
    };
    setCompany(editCompany);
  };

  // set Tab Title
  useEffect(() => {
    document.title = 'Firma | Verwaltung';
  }, []);

  // update addresses
  useEffect(() => {
    const getAddresses = async () => {
      const response = await getAllPagedForSelect('addresses');
      const result = response._embedded?.addresses
        .map((address) => ({ label: `${address.street} ${address.houseNumber}, ${address.city}`, value: address._links.address.href }));
      setAddresses(result);
    };

    if (addresses === undefined) {
      getAddresses();
    }
  }, []);

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  useEffect(() => {
    if (company._links !== undefined) {
      setSelfLink(company._links.self.href);
    }

    // sets formik values
    if (formikRef.current && company !== formikRef.current.values) {
      formikRef.current.setValues(company);
    }

    // sets address values for multi select
    // compares the addresses of the company with the addresses from the api
    // if the address is in the company, it will be added to the addressValues
    if (addresses !== undefined && addresses.length > 0) {
      const values = addresses.filter((address) => company.addresses.includes(address.value));
      setAddressValues(values);
    }
  }, [company]);

  return (
    <div className="container-fluid">
      <ButtonCard title="Firma" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Firma"
          setEntity={setCompany}
          defaultEntity={defaultCompany}
          settersToReset={[setAddressValues, setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={company}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.COMPANIES,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={companyValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values, setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} onReset={handleReset} noValidate>
                    <Modal.Body>
                      <Form.Group controlId="company-group">
                        <InputField
                          value={values.name}
                          handleChange={handleChange}
                          touched={touched.name}
                          errorDescription={errors.name}
                          fieldName="name"
                          placeholder="Name*"
                          props={{ className: 'mb-2' }}
                        />
                        <MultiSelect
                          name="addresses"
                          placeholder="Adressen"
                          options={addresses}
                          values={addressValues}
                          setTemplateValues={setAddressValues}
                          setFieldValue={setFieldValue}
                          touched={touched.addresses}
                          errorDescription={errors.addresses}
                        />
                        <InputField
                          value={values.customerNumber}
                          handleChange={handleChange}
                          touched={touched.customerNumber}
                          errorDescription={errors.customerNumber}
                          fieldName="customerNumber"
                          placeholder="Kunden Nr."
                        />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <ButtonGroup
                        handleDelete={() => handleDelete(selfLink, setUpdate)}
                        selfLink={selfLink !== undefined}
                        setShowDynModal={setShowDynModal}
                        setClassNameDynModal={setClassNameDynModal}
                      />
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
              )}
        />
        <PagedTable
          entityName={Bivema.COMPANIES}
          columns={columnsCompany}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={handleSetCompany}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default CompanyOverview;
