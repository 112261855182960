import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import getByPath from '../../api/GetByPath';
import TitleSubtitleCard from '../../components/cards/TitleSubtitleCard';
import CourseParticipantsCount from '../../components/report/CourseParticipantsCount';

const Dashboard = () => {
  const [courseParticipantCount, setCourseParticipantCount] = useState();

  // update report
  useEffect(() => {
    const getCourseParticipantsCount = async () => {
      const response = await getByPath('report/course/courseParticipantsCount');

      setCourseParticipantCount(response);
    };

    if (courseParticipantCount === undefined) {
      getCourseParticipantsCount();
    }
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col>
          <TitleSubtitleCard
            title="Teilnahmen pro Bildungsveranstaltung"
            subtitle="Nur Anmeldungen zu aktiven Bildungsveranstaltungen mit Status Teilnahme werden berücksichtigt. Der Wert 'Ohne Umzuschulende' ist nur für PV-Kurse relevant."
          >
            <CourseParticipantsCount courseParticipantCount={courseParticipantCount} />
          </TitleSubtitleCard>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
