import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import SingleSelect from '../../components/form/selects/SingleSelect';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';
import { Renderers } from '../../components/table/Renderer';
import getAllPagedForSelect from '../../api/GetAllPagedForSelect';
import { getSelfLink } from '../../utils/Utils';

const columnsFees = [
  {
    title: 'Betrag in Euro',
    name: 'amountInCents',
    renderer: Renderers.CentsToEuro,
  },
  {
    title: 'Dozent',
    name: Bivema.LECTURER,
    relation: Bivema.LECTURER,
    renderer: Renderers.FullName,
  },
  {
    title: 'Bildungsveranstaltung',
    name: 'courseNumber',
    relation: Bivema.COURSE,
  },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultFee = {
  amountInCents: '', lecturer: '', course: '',
};

const feeValidationSchema = Yup.object().shape({
  amountInCents: Yup.number()
    .transform((_, value) => {
      if (value.includes('.')) {
        return null;
      }
      return +value.replace(/,/, '.');
    })
    .typeError('Bitte geben Sie eine Zahl ein.')
    .moreThan(0, 'Bitte geben Sie eine positive Zahl ein.')
    .required('Bitte geben Sie einen Betrag in Euro ein.'),
  lecturer: Yup.string()
    .required('Bitte geben Sie einen Dozierenden ein.'),
  course: Yup.string()
    .required('Bitte geben Sie eine Bildungsveranstaltung ein.'),
});

const FeesOverview = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [lecturers, setLecturers] = useState();
  const [courses, setCourses] = useState();
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');

  const [fee, setFee] = useState({
    ...defaultFee,
  });
  const convertCentToEuro = (centAmount) => {
    const euroAmount = centAmount / 100;
    return euroAmount.toFixed(2); // Rundet den Euro-Betrag auf zwei Dezimalstellen
  };

  const handleSetFee = (value) => {
    const euroAmount = convertCentToEuro(value.amountInCents);

    const editFee = {
      ...value,
      amountInCents: euroAmount, // Hier wird der Betrag in Euro umgewandelt
      lecturer: getSelfLink(value.lecturer),
      course: getSelfLink(value.course),
    };

    setFee(editFee);
  };

  // set Tab Title
  useEffect(() => {
    document.title = 'Honorar | Stammdaten';
  }, []);

  // update lecturers
  useEffect(() => {
    const getLecturers = async () => {
      const response = await getAllPagedForSelect('lecturers');
      const result = response._embedded?.lecturers
        .map((lecturer) => ({ label: `${lecturer.lastName} ${lecturer.firstName}`, value: lecturer._links.lecturer.href }));
      setLecturers(result);
    };

    if (lecturers === undefined) {
      getLecturers();
    }
  }, [lecturers]);

  // update courses
  useEffect(() => {
    const getCourses = async () => {
      const response = await getAllPagedForSelect('courses');
      const result = response._embedded?.courses
        .map((course) => ({ label: `${course.courseNumber}`, value: course._links.course.href }));
      setCourses(result);
    };

    if (courses === undefined) {
      getCourses();
    }
  }, [courses]);

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  useEffect(() => {
    if (fee._links !== undefined) {
      setSelfLink(fee._links.self.href);
    }

    // sets formik values
    if (formikRef.current && fee !== formikRef.current.values) {
      formikRef.current.setValues(fee);
    }
  }, [fee]);
  return (
    <div className="container-fluid">
      <ButtonCard title="Honorar" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Honorar"
          setEntity={setFee}
          defaultEntity={defaultFee}
          settersToReset={[setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={fee}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.FEES,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={feeValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values, setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} onReset={handleReset} noValidate>
                    <Modal.Body>
                      <Form.Group controlId="fee-group">
                        <InputField
                          value={values.amountInCents}
                          handleChange={handleChange}
                          touched={touched.amountInCents}
                          errorDescription={errors.amountInCents}
                          fieldName="amountInCents"
                          placeholder="Betrag in Euro*"
                          props={{ className: 'mb-2' }}
                        />
                        <SingleSelect
                          name="lecturer"
                          placeholder="Dozierend*"
                          // pass object to defaultValue, so it be displayed in the select
                          value={lecturers?.find((a) => a.value === values.lecturer)}
                          values={lecturers}
                          setFieldValue={setFieldValue}
                          touched={touched.lecturer}
                          errorDescription={errors.lecturer}
                        />
                        <SingleSelect
                          name="course"
                          placeholder="Bildungsveranstaltung*"
                          // pass object to defaultValue, so it be displayed in the select
                          value={courses?.find((a) => a.value === values.course)}
                          values={courses}
                          setFieldValue={setFieldValue}
                          touched={touched.course}
                          errorDescription={errors.course}
                        />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <ButtonGroup
                        handleDelete={() => handleDelete(selfLink, setUpdate)}
                        selfLink={selfLink !== undefined}
                        setShowDynModal={setShowDynModal}
                        setClassNameDynModal={setClassNameDynModal}
                      />
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
              )}
        />

        <PagedTable
          entityName={Bivema.FEES}
          columns={columnsFees}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={handleSetFee}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default FeesOverview;
