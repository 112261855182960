import SuccessToast from '../components/toasts/SuccessToast';
import ErrorToastWithButton from '../components/toasts/ErrorToastWithButton';
import { logger } from '../wrapper/Logger';
import KeycloakUtil from '../utils/KeycloakUtil/KeycloakUtil';

const deleteByUrl = async (url) => {
  try {
    const authorization = `Bearer ${KeycloakUtil.getToken()}`;
    const response = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Authorization: authorization,
      },
    });
    const dataFromJson = await response.json();
    if (response.status === 409) {
      throw new Error(JSON.stringify(dataFromJson));
    }
    SuccessToast('Löschen war erfolgreich');
    return dataFromJson;
  } catch (error) {
    ErrorToastWithButton(error);
    logger.error({ error });
    return null;
  }
};
export default deleteByUrl;
