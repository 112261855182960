import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import './Sidebar.css';
import iconAddress from '../../assets/modo-icons/Icons/svg/travel_location_map.svg';
import iconSubject from '../../assets/modo-icons/Icons/svg/education_books.svg';
import iconStudent from '../../assets/modo-icons/Icons/svg/education_student.svg';
import iconDashboard from '../../assets/modo-icons/Icons/svg/finance_graph_arrow_001.svg';
import iconAdd from '../../assets/modo-icons/Icons/svg/user_male_add.svg';
import iconCourse from '../../assets/modo-icons/Icons/svg/education_academic_cap.svg';
import iconRoom from '../../assets/modo-icons/Icons/svg/construction_door.svg';
import iconLecturer from '../../assets/modo-icons/Icons/svg/education_teacher.svg';
import iconTimetable from '../../assets/modo-icons/Icons/svg/travel_clock_calendar.svg';
import iconCustomer from '../../assets/modo-icons/Icons/svg/shopping_customer.svg';
import iconParticipantStatus from '../../assets/modo-icons/Icons/svg/user_head_idea.svg';
import iconCourseType from '../../assets/modo-icons/Icons/svg/education_reading.svg';
import iconSponsor from '../../assets/modo-icons/Icons/svg/finance_investment.svg';
import iconTemplate from '../../assets/modo-icons/Icons/svg/education_clipboard.svg';
import iconAzavCertification from '../../assets/modo-icons/Icons/svg/education_diploma.svg';
import iconTeachingFormat from '../../assets/modo-icons/Icons/svg/education_folder.svg';
import iconSkills from '../../assets/modo-icons/Icons/svg/user_male_settings.svg';
import iconTargetGroup from '../../assets/modo-icons/Icons/svg/user_male_target.svg';
import iconFee from '../../assets/modo-icons/Icons/svg/finance_money_cash_stack.svg';
import iconCompany from '../../assets/modo-icons/Icons/svg/construction_house_002.svg';
import iconContactPerson from '../../assets/modo-icons/Icons/svg/mobile_contact_list.svg';

const Sidebar = () => {
  let linksInSidebar;
  const location = useLocation();
  const managementSidebarList = [
    { pfad: '/management', title: 'Dashboard', icon: iconDashboard },
    { pfad: '/participants', title: 'Teilnehmende', icon: iconStudent },
    { pfad: '/enrollment', title: 'Anmeldung', icon: iconAdd },
    { pfad: '/addresses', title: 'Adresse', icon: iconAddress },
    { pfad: '/company', title: 'Firma', icon: iconCompany },
    { pfad: '/contactPerson', title: 'Ansprechpartner', icon: iconContactPerson },
    { pfad: '/invoiceRecipient', title: 'Rechnungsempfänger', icon: iconCustomer },
    { pfad: '/courses', title: 'Bildungsveranstaltung', icon: iconCourse },
    { pfad: '/timetable', title: 'Stundenplan', icon: iconTimetable },
    { pfad: '/subjects', title: 'Fach', icon: iconSubject },
    { pfad: '/lecturers', title: 'Dozierende', icon: iconLecturer },
    { pfad: '/rooms', title: 'Raum', icon: iconRoom },
  ];
  const referencedataSidebarList = [
    { pfad: '/sponsors', title: 'Kostenträger', icon: iconSponsor },
    { pfad: '/fees', title: 'Honorar', icon: iconFee },
    { pfad: '/skills', title: 'Skills', icon: iconSkills },
    { pfad: '/qualifications', title: 'Schulabschluss', icon: iconStudent },
    { pfad: '/templates', title: 'Template', icon: iconTemplate },
    { pfad: '/targetGroups', title: 'Zielgruppe', icon: iconTargetGroup },
    { pfad: '/participantStatus', title: 'Anmeldungs-Status', icon: iconParticipantStatus },
    { pfad: '/courseTypes', title: 'BV-Art', icon: iconCourseType },
    { pfad: '/courseStatuses', title: 'BV-Status', icon: iconParticipantStatus },
    { pfad: '/teachingFormat', title: 'Durchführungsart', icon: iconTeachingFormat },
    { pfad: '/azavCertifications', title: 'Azav Zertifizierung', icon: iconAzavCertification },
  ];
  const reportsSidebarList = [
    { pfad: '/reports', title: 'Auswertung' },
    { pfad: '/turnover', title: 'Umsatz' },
    { pfad: '/invoices', title: 'rechnung' },
  ];
  const generateSidebarLinks = (liste) => {
    return liste.map((element) => (
      <li key={element.pfad}>
        <NavLink
          to={element.pfad}
          className={({ isActive }) => (isActive
            ? 'nav-link active'
            : 'nav-link')}
        >
          <img
            width="20"
            height="20"
            src={element.icon}
            alt="-"
            className="me-3 svg-panther"
          />
          {element.title}
        </NavLink>
      </li>
    ));
  };

  // Check the path and generate the according sidebar links
  if (
    referencedataSidebarList.some((element) => element.pfad === location.pathname)
  ) {
    linksInSidebar = generateSidebarLinks(referencedataSidebarList);
  } else if (
    managementSidebarList.some((element) => element.pfad === location.pathname)
  ) {
    linksInSidebar = generateSidebarLinks(managementSidebarList);
  } else if (
    reportsSidebarList.some((element) => element.pfad === location.pathname)
  ) {
    linksInSidebar = generateSidebarLinks(reportsSidebarList);
  } else {
    linksInSidebar = null;
  }
  // Render sidebar with links
  return (
    <div className="bg-sand">
      <div id="sidebar" className="sidebar">
        <ul className="nav flex-column">{linksInSidebar}</ul>
      </div>
    </div>
  );
};

export default Sidebar;
