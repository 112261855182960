import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './index.css';
import KeycloakUtil from './utils/KeycloakUtil/KeycloakUtil';

const redirectToHttps = async () => {
  if (!window.location.hostname.includes('localhost') && window.location.protocol !== 'https:') {
    window.location.href = window.location.href.replace('http', 'https');
  }
};

function renderApp() {
  ReactDOM.createRoot(document.getElementById('root')).render(
    <App />,
  );
}

redirectToHttps().then(() => {
  if (window.location.hostname.includes('localhost') || window.location.protocol === 'https:') {
    KeycloakUtil.initKeycloak().then(() => {
      if (KeycloakUtil.hasRole(['academy_bivema'])) {
        renderApp();
      } else {
        ReactDOM.createRoot(document.getElementById('root')).render(
          <div className="vh-100 d-flex justify-content-center align-items-center flex-column">
            <h1>Forbidden</h1>
            <h3>You do not have the required role ACADEMY_BIVEMA assigned.</h3>
          </div>,
        );
      }
    });
  }
});
