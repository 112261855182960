import FloatingLabel from 'react-bootstrap/FloatingLabel';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import React, { useRef, useState } from 'react';
import iconSearch from '../../assets/modo-icons/Icons/svg/mobile_search.svg';
import iconClear from '../../assets/modo-icons/Icons/svg/coding_button_fail.svg';

export const initialSearchTerm = '';

/** The PagedTableSearch component provides an input field which sets the searchTerm
 * via setSearchTerm when the search button is clicked or enter is pressed while not loading.
 * There is also a clear button which resets the searchTerm.
 * resetSearch is needed to reset the searchTerm to the initial value with side effects,
 * e.g. clearing the paging or table.
 * @param {string} searchTerm - the current search term state
 * @param {function} setSearchTerm - the function to set the search term state
 * @param {function} resetSearch - the function to reset the search term state with side effects.
 * @param {boolean} loading - the current loading state
 */
const PagedTableSearch = ({
  searchTerm, setSearchTerm, resetSearch, loading,
}) => {
  // term that is typed into the input and continuously updated
  const [searchValue, setSearchValue] = useState(initialSearchTerm);
  // reference to the input field
  const searchRef = useRef(initialSearchTerm);

  // set searchTerm to searchValue if searchValue if it matches requirements
  const search = () => {
    if (searchValue !== initialSearchTerm && searchValue !== searchTerm && searchValue.trim() !== '' && !loading) {
      setSearchTerm(searchValue);
    }
  };

  // clear search term and input field
  // do not use setSearchTerm(initialSearchTerm) directly, as this will not clear paging.
  const clearSearch = () => {
    if (searchTerm !== initialSearchTerm) {
      resetSearch();
      setSearchValue(initialSearchTerm);
      searchRef.current.value = initialSearchTerm;
    }
  };

  // if enter is pressed, search
  const searchInputKeyHandler = (event) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  // if input is cleared, clear Search
  // if input is changed otherwise, set searchValue
  const searchInputChangeHandler = (event) => {
    const searchInput = event.target.value;
    if (searchInput.length === 0) {
      clearSearch();
    } else {
      setSearchValue(searchInput);
    }
  };

  return (
    <InputGroup>
      <FloatingLabel
        controlId="floatingInput"
        label="Suche"
        className="mb-3 bg-sand"
      >
        <Form.Control type="text" placeholder="Suche" className="bg-sand" ref={searchRef} defaultValue={searchValue} onChange={searchInputChangeHandler} onKeyDown={searchInputKeyHandler} />
      </FloatingLabel>
      {searchValue !== searchTerm || searchValue === initialSearchTerm ? (
        <Button variant="outline-light border-change bg-change" id="button-addon2" className="mb-3" onClick={search}>
          <img
            height="20"
            src={iconSearch}
            alt="-"
            className="svg-white me-2 ms-2"
          />
        </Button>
      ) : (
        <Button variant="outline-light border-change bg-change" id="button-addon2" className="mb-3" onClick={clearSearch}>
          <img
            height="20"
            src={iconClear}
            alt="-"
            className="svg-white me-2 ms-2"
          />
        </Button>
      )}
    </InputGroup>
  );
};

export default PagedTableSearch;
