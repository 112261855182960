// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* makes the table paging number's background mischok change */
.active > .page-link, .page-link.active {
    background-color: rgb(0, 24, 92) !important;
    border-color: rgb(0, 24, 92) !important;
    color: white !important;
}

.active > .page-item, .page-link {
    color: black;
}
.active > .page-item, .page-link:hover {
    color: rgb(0, 24, 92);
}

.active > .page-item, .page-link.active {
    color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/table/Paginator.css"],"names":[],"mappings":"AAAA,8DAA8D;AAC9D;IACI,2CAA2C;IAC3C,uCAAuC;IACvC,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["/* makes the table paging number's background mischok change */\n.active > .page-link, .page-link.active {\n    background-color: rgb(0, 24, 92) !important;\n    border-color: rgb(0, 24, 92) !important;\n    color: white !important;\n}\n\n.active > .page-item, .page-link {\n    color: black;\n}\n.active > .page-item, .page-link:hover {\n    color: rgb(0, 24, 92);\n}\n\n.active > .page-item, .page-link.active {\n    color: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
