import SuccessToast from '../components/toasts/SuccessToast';
import KeycloakUtil from '../utils/KeycloakUtil/KeycloakUtil';

async function patchByUrl(url, entity) {
  try {
    const authorization = `Bearer ${KeycloakUtil.getToken()}`;
    const response = await fetch(url, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
      body: JSON.stringify(entity),
    });
    const errorResponse = await response.json();
    if (!response.ok) {
      throw new Error(JSON.stringify(errorResponse));
    }
    SuccessToast('Änderungen übernommen');
  } catch (error) {
    return error;
  }
  return null;
}
export default patchByUrl;
