import { Alert } from 'react-bootstrap';
import RoutesBivema from './RoutesBivema';

function App() {
  const stage = process.env.REACT_APP_STAGE;
  return (
    <>
      <RoutesBivema />
      {stage === 'dev' && (
      <Alert variant="info" className="ms-3 me-3">
        Du befindest dich auf der DEVELOPMENT Instanz von Bivema mit lokalen Daten.
      </Alert>
      )}
      {stage === 'staging' && (
      <Alert variant="warning" className="ms-3 me-3">
        Du befindest dich auf der STAGING Instanz von Bivema mit Testdaten.
        Die Testdaten sind ein anonymisierter Stand der Produktivdatenbank.
      </Alert>
      )}
    </>
  );
}

export default App;
