/* eslint-disable no-alert */
import { Button } from 'react-bootstrap';
import React from 'react';
import { toast } from 'react-toastify';
import './Toast.css';
import iconError from '../../assets/modo-icons/Icons/svg/coding_button_fail.svg';

async function onCopyDetails(errorDetails) {
  const details = JSON.stringify(errorDetails);
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(details);
    alert(
      'Die technischen Details des Fehlers wurden kopiert'
        + ' und können jetzt z.B. mit Strg-V in einer Mail eingefügt werden.'
        + '\n\n'
        + 'Die technischen Details sehen circa so aus:'
        + `\n\n${
          details.slice(0, 300)
        } ...`,

    );
  } else {
    alert(details);
  }
}

export default function ErrorToastWithButton(error) {
  toast(
    <>
      <div>
        <img src={iconError} alt="text" className="svg-warning svg-icon" />
      </div>
      <div className="toast-div-label">
        Ein Fehler ist aufgetreten.
      </div>
      <Button
        size="sm"
        variant="outline-secondary"
        className="error-toast-button"
        onClick={(e) => {
          e.stopPropagation();
          onCopyDetails({ message: error.message });
        }}
      >
        Copy
      </Button>
    </>,
    { autoClose: false },
  );
}
