/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Badge } from 'react-bootstrap';
import iconFalse from '../../assets/modo-icons/Icons/svg/coding_button_fail.svg';
import iconTrue from '../../assets/modo-icons/Icons/svg/coding_button_success.svg';
import './Renderer.css';

export const Renderers = {
  Hide: 'hide',
  FullName: 'fullName',
  Addresses: 'addresses',
  Address: 'address',
  ContactPersons: 'contactPersons',
  Courses: 'courses',
  InvoiceRecipients: 'invoiceRecipients',
  Templates: 'templates',
  Rooms: 'rooms',
  Boolean: 'boolean',
  Date: 'date',
  DateTime: 'dateTime',
  CentsToEuro: 'centsToEuro',
  InvoiceRecipientsWithCompany: 'invoiceRecipientsWithCompany',
  NumberOfData: 'numberOfData',
};

const Renderer = ({ renderer, data }) => {
  // Boolean renderer
  if (renderer === Renderers.Boolean) {
    return (
      <div>
        {data
          ? <img src={iconTrue} height={20} className="svg-success" alt="true" />
          : <img src={iconFalse} height={20} className="svg-warning" alt="false" />}
      </div>
    );
  }

  // Fullname renderer
  if (renderer === Renderers.FullName) {
    return (
      <div>
        {`${data.firstName ? `${data.firstName} ` : ''}${data.lastName ? `${data.lastName}` : ''}`}
      </div>
    );
  }

  // Addresses renderer
  if (renderer === Renderers.Addresses) {
    return (
      <div>
        {data.map((address) => (
          <>
            {`${address.street ? `${address.street} ` : ''}${address.houseNumber ? `${address.houseNumber}` : ''}${address.postalCode || address.city ? ', ' : ''}${address.postalCode ? `${address.postalCode} ` : ''}${address.city ? `${address.city}` : ''}`}
            <br />
          </>
        ))}
      </div>
    );
  }

  // Address renderer
  if (renderer === Renderers.Address) {
    return (
      <div>
        {`${data.street ? `${data.street} ` : ''}${data.houseNumber ? `${data.houseNumber}` : ''}${data.postalCode || data.city ? ', ' : ''}${data.postalCode ? `${data.postalCode} ` : ''}${data.city ? `${data.city}` : ''}`}
      </div>
    );
  }

  // ContactPersons renderer
  if (renderer === Renderers.ContactPersons) {
    return (
      <div>
        {data.map((contactPerson) => (
          <>
            {`${contactPerson.firstName ? `${contactPerson.firstName} ` : ''} ${contactPerson.lastName ? `${contactPerson.lastName}` : ''}`}
            <br />
          </>
        ))}
      </div>
    );
  }

  // InvoiceRecipients renderer
  if (renderer === Renderers.InvoiceRecipients) {
    return (
      <div>
        {data.map((invoiceRecipient) => (
          <>
            {`${invoiceRecipient.firstName ? `${invoiceRecipient.firstName} ` : ''}${invoiceRecipient.lastName ? `${invoiceRecipient.lastName} ` : ''}${invoiceRecipient.email ? '(' : ''}${invoiceRecipient.email ? `${invoiceRecipient.email}` : ''}${invoiceRecipient.email ? ')' : ''}`}
            <br />
          </>
        ))}
      </div>
    );
  }

  // InvoiceRecipientsWithCompany renderer
  // only indicates if a company is linked to the invoice recipient but doesn't show its name
  if (renderer === Renderers.InvoiceRecipientsWithCompany) {
    return (
      <div>
        {`
    ${data.firstName ? data.firstName : ''}
    ${data.lastName ? data.lastName : ''}
    ${(data.firstName || data.lastName) && data.email ? ' | ' : ''}
    ${data.email ? data.email : ''}
    ${(data.email || (data.firstName || data.lastName)) && data._links.company ? ' | ' : ''}
    ${data.company ? data.company.name : ''}
            `}
      </div>
    );
  }

  // NumberOfData renderer
  if (renderer === Renderers.NumberOfData) {
    return (
      <div>
        {/* Only show number of entries in data */}
        {data.length}
      </div>
    );
  }

  // Templates renderer
  if (renderer === Renderers.Templates) {
    return (
      <div>
        {data.map((template) => (
          template.shortDescription ? <Badge pill bg="secondary" className="me-1">{template.shortDescription}</Badge> : ''
        ))}
      </div>
    );
  }

  // Courses renderer
  if (renderer === Renderers.Courses) {
    return (
      <div>
        {data.map((course) => (
          course.courseNumber ? <Badge pill bg="info" className="me-1 bg-change">{course.courseNumber}</Badge> : ''
        ))}
      </div>
    );
  }

  // Rooms renderer
  if (renderer === Renderers.Rooms) {
    return (
      <div>
        {data.map((room) => (
          room.number ? <Badge pill bg="secondary" className="me-1">{room.number}</Badge> : ''
        ))}
      </div>
    );
  }

  // Date renderer
  if (renderer === Renderers.Date) {
    return (
      <div>
        {data ? new Intl.DateTimeFormat('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(new Date(data)) : ''}
      </div>
    );
  }

  // DateTime renderer
  if (renderer === Renderers.DateTime) {
    const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit' };
    const date = data && new Date(data);
    return (
      <div className="monospace">
        {date ? `${date.toLocaleDateString('de-DE', optionsDate)} - ${date.toLocaleTimeString('de-DE', optionsTime)}` : '' }
      </div>
    );
  }

  // CentsToEuro renderer
  if (renderer === Renderers.CentsToEuro) {
    return (
      <div>
        {data ? `${(data / 100).toFixed(2).replace('.', ',')} €` : ''}
      </div>
    );
  }

  // Hide renderer
  if (renderer === Renderers.Hide) {
    return (
      <div />
    );
  }

  // Default renderer
  return (
    <div>
      {data ? `${data} ` : ''}
    </div>
  );
};

export default Renderer;
