import ErrorToastWithButton from '../components/toasts/ErrorToastWithButton';
import { logger } from '../wrapper/Logger';
import getFetchConfig, { HttpMethod, apiBaseUrl } from './Common';

/**
 * Use getByPath to get data from the REST API by a specific path.
 * The path is added to the base URL.
 * @param {*} path - the path to the REST API endpoint
 * @returns JSON data from REST API
 */
const getByPath = async (path) => {
  try {
    const url = `${apiBaseUrl + path}`;

    const response = await fetch(url, getFetchConfig(HttpMethod.GET));

    const jsonData = await response.json();
    if (response.status === 404 || response.status === 409) {
      throw new Error(JSON.stringify(jsonData));
    }
    return jsonData;
  } catch (error) {
    logger.error(error);
    ErrorToastWithButton(error);
    return null;
  }
};
export default getByPath;
