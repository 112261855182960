import ErrorToastWithButton from '../components/toasts/ErrorToastWithButton';
import { logger } from '../wrapper/Logger';
import getFetchConfig, { HttpMethod, apiBaseUrl } from './Common';

/**
 * Use the [entityName]/search/all?page[]&size=[]&term=[] endpoint of the REST API
 * to search paginated for a term in an entity.
 * @param {*} entityName is the name of the entity according to REST API
 * @param {*} searchTerm is the search term
 * @param {*} page which page to show
 * @param {*} pageSize how many items per page
 * @returns JSON data from REST API
 */
const searchPaged = async (entityName, searchTerm, page = 0, pageSize = 20) => {
  try {
    const url = `${apiBaseUrl + entityName}/search/all?page=${page}&size=${pageSize}&term=${encodeURIComponent(searchTerm.toLowerCase())}`;

    const response = await fetch(url, getFetchConfig(HttpMethod.GET));
    const jsonData = await response.json();
    if (response.status === 404 || response.status === 409) {
      throw new Error(JSON.stringify(jsonData));
    }
    return jsonData;
  } catch (error) {
    logger.error(error);
    ErrorToastWithButton(error);
    return null;
  }
};
export default searchPaged;
