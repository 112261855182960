/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

/**
 * A floating label input field. With output of an error description if invalid
 *
 * @param {*} value value of the field
 * @param {*} handleChange On change function of the field,
 * default pass formik handleChange
 * @param {*} touched Boolean if the field was touched
 * @param {*} errorDescription Error description of the field, when invalid
 * @param {*} type Type of the input field, default 'text'
 * @param {*} fieldName Name of the field
 * @param {*} placeholder Placeholder of the field
 * @param {*} props Additional information like style or classname, for the label
 * @param {*} defaultValue Default value for the input field if value is not provided
 *
 * @return {*}
 */
const InputField = ({
  value,
  handleChange,
  touched,
  errorDescription,
  type = 'text',
  fieldName,
  placeholder,
  props,
  disabled = false,
  defaultValue,
}) => {
  return (
    <FloatingLabel
      controlId="floatingInput"
      label={placeholder}
      {...props}
    >
      <Form.Control
        type={type}
        name={fieldName}
        placeholder={placeholder}
        value={value !== '' ? value : defaultValue}
        onChange={handleChange}
        isValid={touched && !errorDescription}
        isInvalid={!!errorDescription}
        disabled={disabled}
      />
      <Form.Control.Feedback type="invalid">
        {errorDescription}
      </Form.Control.Feedback>
    </FloatingLabel>
  );
};

export default InputField;
