import Form from 'react-bootstrap/Form';

const CheckBox = ({
  handleChange,
  checked,
  type = 'checkbox',
  fieldname,
  label,
}) => {
  return (
    <div
      className="form-control checkbox-border"
    >
      <Form.Check
        type={type}
        name={fieldname}
        label={label}
        onChange={handleChange}
        checked={checked}
      />
    </div>
  );
};

export default CheckBox;
