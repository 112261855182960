import Select from 'react-select';
import { borderColor, selectMenuToFront, placeholderColor } from './SelectStyle';
import sortObjectArray from '../../../utils/Utils';

/**
 * A multi select wich is search and clearable. It is not closing after select.
 *
 * @param {*} name Name of the select. Use the object field name of the entity.
 * @param {*} placeholder Placeholder of the field
 * @param {*} options List of options for the select. Each list item is an object,
 * consisting of value and label
 * @param {*} touched Boolean if the field was touched
 * @param {*} errorDescription Error description of the field, when invalid
 * @param {*} values values of the field.
 * Each value is an object consisting of value and label,
 * where label is displayed in select and value is used for post.
 * @param {*} setTemplateValues Setter to set template values
 * @param {*} setFieldValue Formik setFieldValue function
 * @param {*} width width of the control, default '100%'
 * @param {*} height height of the control, default '100%'
 * @param {*} minHeight set the minimum height of the control, default 58
 * @param {*} disabled Boolean if the field is disabled
 * @param {*} closeMenuOnSelect Boolean if the menu should close after select
 * @param {*} closeMenuOnScroll Boolean if the menu should close on scroll
 *
 * @return {*}
 */
const MultiSelect = ({
  name,
  placeholder,
  options,
  touched,
  errorDescription,
  values,
  setTemplateValues,
  setFieldValue,
  width = '100%',
  height = '100%', // height of the select box
  minHeight = 58, // minimum height of the select box
  closeMenuOnSelect = true,
  closeMenuOnScroll = false,
  disabled = false,
}) => {
  const customStyle = {
    control: (provided, state) => ({
      ...provided,
      ...borderColor(state, errorDescription, touched),
      height,
      minHeight,
      width,
    }),
  };
  sortObjectArray(values, 'label');
  return (
    <div className="mb-2">
      <Select
        isMulti
        isSearchable
        placeholder={placeholder}
        onChange={(e) => {
          setTemplateValues(e);
          // set the field value to an string array, so it can be posted
          setFieldValue(name, e.map((a) => a.value));
        }}
        name={name}
        options={options}
        // value needs to be an object, otherwise the select will not display the value
        value={values || ''} // '' resets the select after new entity is created
        className={errorDescription === undefined ? 'basic-multi-select' : 'basic-multi-select is-invalid'}
        classNamePrefix="multi-"
        styles={Object.assign(
          selectMenuToFront,
          placeholderColor,
          customStyle,
        )}
        isClearable
        menuPosition="fixed"
        isLoading={options === undefined}
        closeMenuOnSelect={closeMenuOnSelect}
        closeMenuOnScroll={closeMenuOnScroll}
        isDisabled={disabled}
      />
      <div className="invalid-feedback">{errorDescription}</div>
    </div>
  );
};

export default MultiSelect;
