import { useState } from 'react';
import { Button } from 'react-bootstrap';
import ConfimationModal from './ConfirmationModal';

const ButtonGroup = ({
  handleDelete,
  selfLink,
  setShowDynModal,
  setClassNameDynModal,
}) => {
  const [showConfModal, setShowConfModal] = useState(false);

  // setClassName for the DynModal to hide the DynModal (set the opacity to 0.0 in the CSS)
  const handleOnClick = () => {
    setShowConfModal(true);
    setClassNameDynModal('dynModalInBackground');
  };

  const handleClose = () => {
    setShowDynModal(false);
  };

  return (
    <>
      <Button variant="secondary" id="button-close-modal" className="btn btn-secondary bg-panther border-0 text-white text-uppercase" onClick={handleClose}>Schließen</Button>
      {/* shows delete only on edit */}
      {selfLink ? (
        <>
          <Button variant="danger" id="button-delete-modal" className="btn btn-danger bg-warning border-0 text-white text-uppercase" onClick={handleOnClick}>Löschen</Button>
          <ConfimationModal
            setShowConfModal={setShowConfModal}
            showConfModal={showConfModal}
            handleDelete={handleDelete}
            setShowDynModal={setShowDynModal}
            setClassNameDynModal={setClassNameDynModal}
          />
        </>
      )
        : null}
      <Button variant="primary" id="button-submit-modal" className="btn btn-primary bg-change border-0 text-white text-uppercase" type="submit">Speichern</Button>
    </>
  );
};

export default ButtonGroup;
