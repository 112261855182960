import {
  BrowserRouter as Router, Route, Routes, Navigate,
} from 'react-router-dom';
import Layout from './Layout';
import Dashboard from './pages/management/Dashboard';
import Subjects from './pages/management/Subjects';
import Courses from './pages/management/Courses';
import Lecturers from './pages/management/Lecturers';
import Enrollments from './pages/management/Enrollments';
import Addresses from './pages/management/Addresses';
import ParticipantStatuses from './pages/referencedata/ParticipantStatuses';
import Qualifications from './pages/referencedata/Qualifications';
import Rooms from './pages/management/Rooms';
import CourseTypes from './pages/referencedata/CourseTypes';
import Sponsors from './pages/referencedata/Sponsors';
import CourseStatus from './pages/referencedata/CourseStatus';
import ParticipantDetails from './pages/management/ParticipantDetails';
import Template from './pages/referencedata/Templates';
import AzavCertification from './pages/referencedata/AzavCertifications';
import TeachingFormat from './pages/referencedata/TeachingFormats';
import Skill from './pages/referencedata/Skills';
import TargetGroups from './pages/referencedata/TargetGroups';
import Fees from './pages/referencedata/Fees';
import ContactPerson from './pages/management/ContactPersons';
import Company from './pages/management/Company';
import Timetable from './pages/management/Timetable';
import InvoiceRecipients from './pages/management/InvoiceRecipients';
import Participants from './pages/management/Participants';

function RoutesBivema() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate to="/management" />} />
          {/* Management */}
          <Route path="management" element={<Dashboard />} />
          <Route path="participants" element={<Participants />} />
          <Route path="participant/:id" element={<ParticipantDetails />} />
          <Route path="enrollment" element={<Enrollments />} />
          <Route path="courses" element={<Courses />} />
          <Route path="rooms" element={<Rooms />} />
          <Route path="timetable" element={<Timetable />} />
          <Route path="addresses" element={<Addresses />} />
          <Route path="invoiceRecipient" element={<InvoiceRecipients />} />
          <Route path="lecturers" element={<Lecturers />} />
          <Route path="contactPerson" element={<ContactPerson />} />
          <Route path="company" element={<Company />} />
          <Route path="subjects" element={<Subjects />} />
          {/* Reference Data */}
          <Route path="participantStatus" element={<ParticipantStatuses />} />
          <Route path="qualifications" element={<Qualifications />} />
          <Route path="courseTypes" element={<CourseTypes />} />
          <Route path="sponsors" element={<Sponsors />} />
          <Route path="courseStatuses" element={<CourseStatus />} />
          <Route path="templates" element={<Template />} />
          <Route path="azavCertifications" element={<AzavCertification />} />
          <Route path="teachingFormat" element={<TeachingFormat />} />
          <Route path="skills" element={<Skill />} />
          <Route path="targetgroups" element={<TargetGroups />} />
          <Route path="fees" element={<Fees />} />
          {/* Reports */}
        </Routes>
      </Layout>
    </Router>
  );
}

export default RoutesBivema;
