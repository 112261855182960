import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { handleDelete, handleSubmit as modalSubmit } from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import Validation from '../../utils/Validation';
import ValidationErrors from '../../utils/ValidationErrors';
import SingleSelect from '../../components/form/selects/SingleSelect';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';
import { Renderers } from '../../components/table/Renderer';
import getAllPagedForSelect from '../../api/GetAllPagedForSelect';
import { getSelfLink } from '../../utils/Utils';

const columnsInvoiceRecipient = [
  { title: 'Nachname', name: 'lastName' },
  { title: 'Vorname', name: 'firstName' },
  {
    title: 'Firma',
    name: 'name',
    relation: Bivema.COMPANY,
  },
  { title: 'Email', name: 'email' },
  {
    title: 'Adresse',
    name: Bivema.ADDRESS,
    relation: Bivema.ADDRESS,
    renderer: Renderers.Address,
  },
  { title: 'Adresszusatz', name: 'additionalAddressInfo' },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultInvoiceRecipient = {
  firstName: '',
  lastName: '',
  address: '',
  additionalAddressInfo: '',
  email: '',
  company: '',
};

const invoiceRecipientValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable(true)
    .max(255, 'Darf maximal 255 Zeichen lang sein.'),
  lastName: Yup.string()
    .nullable(true)
    .max(255, 'Darf maximal 255 Zeichen lang sein.'),
  address: Yup.string()
    .nullable(true),
  additionalAddressInfo: Yup.string()
    .nullable(true)
    .max(100, 'Darf maximal 100 Zeichen lang sein.'),
  email: Yup.string()
    .max(50, 'Darf maximal 50 Zeichen lang sein.')
    .matches(Validation.email, ValidationErrors.email)
    .required('Bitte geben Sie eine Email ein.'),
  company: Yup.string()
    .nullable(true),
});

const InvoiceRecipientOverview = () => {
  const [companies, setCompanies] = useState();
  const [addresses, setAddresses] = useState();
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');
  const [invoiceRecipient, setInvoiceRecipient] = useState({
    ...defaultInvoiceRecipient,
  });

  const handleSetInvoiceRecipient = (value) => {
    const editInvoiceRecipient = {
      ...value,
      company: getSelfLink(value.company),
      address: getSelfLink(value.address),
    };

    setInvoiceRecipient(editInvoiceRecipient);
  };

  // set Tab Title
  useEffect(() => {
    document.title = 'Rechnugsempfänger | Stammdaten';
  }, []);

  // update companies
  useEffect(() => {
    const getCompanies = async () => {
      const response = await getAllPagedForSelect('companies');
      const result = response._embedded?.companies
        .map((company) => ({ label: company.name, value: company._links.company.href }));
      setCompanies(result);
    };

    if (companies === undefined) {
      getCompanies();
    }
  }, []);

  // update addresses
  useEffect(() => {
    const getAddresses = async () => {
      const response = await getAllPagedForSelect('addresses');
      const result = response._embedded?.addresses
        .map((address) => ({ label: `${address.street} ${address.houseNumber}, ${address.city}`, value: address._links.address.href }));
      setAddresses(result);
    };

    if (addresses === undefined) {
      getAddresses();
    }
  }, []);

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  useEffect(() => {
    if (invoiceRecipient._links !== undefined) {
      setSelfLink(invoiceRecipient._links.self.href);
    }

    // sets formik values
    if (formikRef.current && invoiceRecipient !== formikRef.current.values) {
      formikRef.current.setValues(invoiceRecipient);
    }
  }, [invoiceRecipient]);

  return (
    <div className="container-fluid">
      <ButtonCard title="Rechnungsempfänger" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Rechnungsempfänger"
          setEntity={setInvoiceRecipient}
          defaultEntity={defaultInvoiceRecipient}
          settersToReset={[setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={invoiceRecipient}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.INVOICE_RECIPIENTS,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={invoiceRecipientValidationSchema}
            >
              {({
                errors, touched, handleSubmit, handleReset, handleChange, values, setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} onReset={handleReset} noValidate>
                    <Modal.Body>
                      <Form.Group controlId="invoiceRecipient-group">
                        <InputField
                          value={values.firstName}
                          handleChange={handleChange}
                          touched={touched.firstName}
                          errorDescription={errors.firstName}
                          fieldName="firstName"
                          placeholder="Vorname"
                          props={{ className: 'mb-2' }}
                        />
                        <InputField
                          value={values.lastName}
                          handleChange={handleChange}
                          touched={touched.lastName}
                          errorDescription={errors.lastName}
                          fieldName="lastName"
                          placeholder="Nachname"
                          props={{ className: 'mb-2' }}
                        />
                        <InputField
                          value={values.email}
                          handleChange={handleChange}
                          touched={touched.email}
                          errorDescription={errors.email}
                          fieldName="email"
                          placeholder="Email*"
                          props={{ className: 'mb-2' }}
                        />
                        <SingleSelect
                          name="company"
                          placeholder="Firma"
                          // pass object to defaultValue, so it be displayed in the select
                          value={companies?.find((a) => a.value === values.company)}
                          values={companies}
                          setFieldValue={setFieldValue}
                          touched={touched.company}
                          errorDescription={errors.company}
                        />
                        <SingleSelect
                          name="address"
                          placeholder="Adresse"
                          // pass object to defaultValue, so it be displayed in the select
                          value={addresses?.find((a) => a.value === values.address)}
                          values={addresses}
                          setFieldValue={setFieldValue}
                          touched={touched.address}
                          errorDescription={errors.address}
                        />
                        <InputField
                          value={values.additionalAddressInfo}
                          handleChange={handleChange}
                          touched={touched.additionalAddressInfo}
                          errorDescription={errors.additionalAddressInfo}
                          fieldName="additionalAddressInfo"
                          placeholder="Adresszusatz"
                        />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <ButtonGroup
                        handleDelete={() => handleDelete(selfLink, setUpdate)}
                        selfLink={selfLink !== undefined}
                        setShowDynModal={setShowDynModal}
                        setClassNameDynModal={setClassNameDynModal}
                      />
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
              )}
        />
        <PagedTable
          entityName={Bivema.INVOICE_RECIPIENTS}
          columns={columnsInvoiceRecipient}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={handleSetInvoiceRecipient}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default InvoiceRecipientOverview;
