/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React from 'react';
import iconEdit from '../../assets/modo-icons/Icons/svg/text_editing_document_edit.svg';

import Renderer, { Renderers } from './Renderer';

/** PagedTableRow renders a row of the PagedTable component.
 * It fetches the data for the relations and renders the data.
 * It also shows an edit icon which sets the entity to the state and opens the modal,
 * that it can be edited.
 * @param {object[]} columns - definition of columns to be shown in the table
 * @param {object} rowData - the data of the row, so the entity data
 * @param {function} setShowDynModal - function to set the state of the modal
 * @param {function} setEntity - function to set the state of the entity including the relations
 */
const PagedTableRow = ({
  columns, rowData, setShowDynModal, setEntity,
}) => {
  const handleClick = async () => {
    setShowDynModal(true);

    setEntity(rowData);
  };

  return (
    <tr>
      {columns.map((column) => (
        // only show column if renderer is not Hide
        column.renderer !== Renderers.Hide
          && (
          <td key={column.name}>
            <RenderColumn column={column} rowData={rowData} handleClick={handleClick} />
          </td>
          )
      ))}
    </tr>
  );
};

/** RenderColumn renders a column of the PagedTableRow component.
 * Depending on the column definition it renders the data of the entity or the relation.
 * @param {object} column - definition of the column to be shown in the table
 * @param {object} rowData - the data of the row, so the entity data
 * @param {function} handleClick - function handle click of the edit icon
 * @returns {JSX.Element} - the rendered column
 */
const RenderColumn = ({
  column, rowData, handleClick,
}) => {
  return (
    column.relation !== undefined)
    ? (rowData[column.relation] !== undefined && rowData[column.relation] !== null)
      ? (
        (column.renderer === undefined ? (
        // relation and default renderer
          <Renderer
            data={rowData[column.relation][column.name]}
          />
        ) : (
        // relation and renderer
          <Renderer renderer={column.renderer} data={rowData[column.relation]} />
        )
        )
      ) : (
        <div />
      )
    : (column.title === 'Edit'
      ? (
    // special case for edit icon
        <img
          src={iconEdit}
          onClick={handleClick}
          id="edit-icon"
          style={{ cursor: 'pointer' }}
          className="me-3 svg-panther"
          height={20}
          alt="edit"
        />
      ) : (
      // no relation with renderer or default renderer
        <Renderer renderer={column.renderer} data={rowData[column.name]} />
      )
    );
};

export default PagedTableRow;
