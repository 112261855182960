import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Col, Form, Modal, Row,
} from 'react-bootstrap';
import {
  handleDelete,
  handleSubmit as modalSubmit,
} from '../../components/modals/ModalHelpers';
import DynModal from '../../components/modals/DynModal';
import ButtonCard from '../../components/cards/ButtonCard';
import ButtonGroup from '../../components/modals/ButtonGroup';
import InputField from '../../components/form/InputField';
import Textarea from '../../components/form/Textarea';
import SingleSelect from '../../components/form/selects/SingleSelect';
import PagedTable from '../../components/table/PagedTable';
import Bivema from '../../common/Bivema';
import { Renderers } from '../../components/table/Renderer';
import getAllPagedForSelect from '../../api/GetAllPagedForSelect';
import { getSelfLink } from '../../utils/Utils';

const columnsTemplate = [
  { title: 'Titel', name: 'title' },
  { title: 'Kürzel', name: 'shortDescription' },
  { title: 'Zeitraum', name: 'timePeriod' },
  { title: 'Max. Anzahl', name: 'maxParticipants' },
  {
    title: 'Preis in Euro',
    name: 'priceInCents',
    renderer: Renderers.CentsToEuro,
  },
  {
    title: 'Azav',
    name: 'status',
    relation: Bivema.AZAV_CERTIFICATION,
  },
  { title: 'Edit', name: '_links.self.href' },
];

const defaultTemplate = {
  title: '',
  shortDescription: '',
  timePeriod: '',
  maxParticipants: '',
  priceInCents: '',
  azavCertification: '',
  valueProposition: '',
  scale: '',
  content: '',
  methodology: '',
};

const templateValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Bitte geben Sie einen Titel ein.'),
  shortDescription: Yup.string()
    .required('Bitte geben Sie eine Kurzbeschreibung ein.'),
  timePeriod: Yup.string()
    .nullable(true),
  maxParticipants: Yup.number()
    .nullable(true)
    .typeError('Bitte geben Sie eine Zahl ein.')
    .moreThan(-1, 'Bitte geben Sie eine positive Zahl ein.'),
  priceInCents: Yup.number()
    // to pass the validation, when value includes a dot
    .transform((_, value) => {
      if (value.includes('.')) {
        return null;
      }
      return +value.replace(/,/, '.');
    })
    .nullable(true)
    .typeError('Bitte geben Sie eine Zahl ein.')
    .moreThan(-1, 'Bitte geben Sie eine positive Zahl ein.'),
  azavCertification: Yup.string()
    .nullable(true),
  valueProposition: Yup.string()
    .nullable(true),
  scale: Yup.number()
    .nullable(true)
    .typeError('Bitte geben Sie eine Zahl ein.')
    .moreThan(-1, 'Bitte geben Sie eine positive Zahl ein.'),
  content: Yup.string()
    .nullable(true),
  methodology: Yup.string()
    .nullable(true),
});

const TemplateOverview = () => {
  const [update, setUpdate] = useState(false);
  const [selfLink, setSelfLink] = useState();
  const [azavCertifications, setAzavCertifications] = useState();
  const [showDynModal, setShowDynModal] = useState(false);
  const [classNameDynModal, setClassNameDynModal] = useState('dynModal');

  const [template, setTemplate] = useState({
    ...defaultTemplate,
  });

  const convertCentToEuro = (centAmount) => {
    const euroAmount = centAmount / 100;
    return euroAmount.toFixed(2); // Rundet den Euro-Betrag auf zwei Dezimalstellen
  };

  const handleTemplate = (value) => {
    const euroAmount = convertCentToEuro(value.priceInCents);

    const editTemplate = {
      ...value,
      priceInCents: euroAmount,
      azavCertification: getSelfLink(value.azavCertification),
    };

    setTemplate(editTemplate);
  };

  // update azavCertifications
  useEffect(() => {
    const getAzavCertifications = async () => {
      const response = await getAllPagedForSelect('azavCertifications');
      const result = response._embedded?.azavCertifications
        .map((azavCertification) => ({
          label: azavCertification.status,
          value: azavCertification._links.azavCertification.href,
        }));
      setAzavCertifications(result);
    };

    if (azavCertifications === undefined) {
      getAzavCertifications();
    }
  }, [azavCertifications]);

  // needed for formik, to pass it to the modal and set the values
  const formikRef = useRef();

  // set Tab Title
  useEffect(() => {
    document.title = 'Template | Stammdaten';
  }, []);

  useEffect(() => {
    if (template._links !== undefined) {
      setSelfLink(template._links.self.href);
    }

    // sets formik values
    if (formikRef.current && template !== formikRef.current.values) {
      formikRef.current.setValues(template);
    }
  }, [template]);

  return (
    <div className="container-fluid">
      <ButtonCard title="Template" className="mt-2" setShowDynModal={setShowDynModal}>
        <DynModal
          className={classNameDynModal}
          headerTitle="Template"
          setEntity={setTemplate}
          defaultEntity={defaultTemplate}
          settersToReset={[setSelfLink]}
          formikRef={formikRef}
          show={showDynModal}
          setShow={setShowDynModal}
          modalSize="lg"
          modalBody={(

            <Formik
              innerRef={formikRef}
              initialValues={template}
              onSubmit={(values, submitProps) => modalSubmit(
                values,
                submitProps,
                setUpdate,
                Bivema.TEMPLATES,
                selfLink,
              )}
              validateOnChange={false} // because at the moment on change validates all fields
              validationSchema={templateValidationSchema}
            >
              {({
                errors,
                touched,
                handleSubmit,
                handleReset,
                handleChange,
                values,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                  <Modal.Body>
                    <Form.Group controlId="template-group">
                      <Row className="mb-2">
                        <Col md={6}>
                          <InputField
                            value={values.title}
                            handleChange={handleChange}
                            touched={touched.title}
                            errorDescription={errors.title}
                            fieldName="title"
                            placeholder="Titel*"
                          />
                        </Col>
                        <Col md={6}>
                          <InputField
                            value={values.shortDescription}
                            handleChange={handleChange}
                            touched={touched.shortDescription}
                            errorDescription={errors.shortDescription}
                            fieldName="shortDescription"
                            placeholder="Kurzbeschreibung*"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={6}>
                          <InputField
                            value={values.timePeriod}
                            handleChange={handleChange}
                            touched={touched.timePeriod}
                            errorDescription={errors.timePeriod}
                            fieldName="timePeriod"
                            placeholder="Zeitraum"
                          />
                        </Col>
                        <Col md={6}>
                          <InputField
                            value={values.maxParticipants}
                            handleChange={handleChange}
                            touched={touched.maxParticipants}
                            errorDescription={errors.maxParticipants}
                            fieldName="maxParticipants"
                            placeholder="Max. Teilnehmer"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={6}>
                          <InputField
                            value={values.priceInCents}
                            handleChange={handleChange}
                            touched={touched.priceInCents}
                            errorDescription={errors.priceInCents}
                            fieldName="priceInCents"
                            placeholder="Preis in Euro"
                          />
                        </Col>
                        <Col md={6}>
                          <SingleSelect
                            name="azavCertification"
                            placeholder="Azav Zertifizierung"
                            // pass object to defaultValue, so it be displayed in the select
                            value={azavCertifications?.find((a) => a.value
                              === values.azavCertification)}
                            values={azavCertifications}
                            setFieldValue={setFieldValue}
                            touched={touched.azavCertification}
                            errorDescription={errors.azavCertification}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={6}>
                          <Textarea
                            value={values.valueProposition}
                            handleChange={handleChange}
                            touched={touched.valueProposition}
                            errorDescription={errors.valueProposition}
                            fieldName="valueProposition"
                            placeholder="Werteversprechen"
                            areaStyleProps={{ height: '180px' }}
                          />
                        </Col>
                        <Col md={6}>
                          <InputField
                            value={values.scale}
                            handleChange={handleChange}
                            touched={touched.scale}
                            errorDescription={errors.scale}
                            fieldName="scale"
                            placeholder="Umfang"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>

                          <Textarea
                            value={values.content}
                            handleChange={handleChange}
                            touched={touched.content}
                            errorDescription={errors.content}
                            fieldName="content"
                            placeholder="Inhalt"
                            areaStyleProps={{ height: '180px' }}
                          />
                        </Col>
                        <Col md={6}>
                          <Textarea
                            value={values.methodology}
                            handleChange={handleChange}
                            touched={touched.methodology}
                            errorDescription={errors.methodology}
                            fieldName="methodology"
                            placeholder="Methodik"
                            areaStyleProps={{ height: '180px' }}
                          />
                        </Col>
                      </Row>

                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonGroup
                      handleDelete={() => handleDelete(selfLink, setUpdate)}
                      selfLink={selfLink !== undefined}
                      setShowDynModal={setShowDynModal}
                      setClassNameDynModal={setClassNameDynModal}
                    />
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          )}
        />
        <PagedTable
          entityName={Bivema.TEMPLATES}
          columns={columnsTemplate}
          pageSize={10}
          setShowDynModal={setShowDynModal}
          setEntity={handleTemplate}
          setUpdate={setUpdate}
          update={update}
        />
      </ButtonCard>
    </div>
  );
};

export default TemplateOverview;
