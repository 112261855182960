import React from 'react';

const ParticipantDetails = () => {
  return (
    <h1>
      Participant Detail Page
    </h1>
  );
};

export default ParticipantDetails;
